import React, { SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  // Card,
  // CardContent,
  // CardHeader,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Dialog,
  DialogTitle,
  Avatar,
  FormControlLabel,
  FormLabel,
  Grid,
  // InputLabel,
  // LinearProgress,
  Link,
  ListItem,
  MenuItem,
  // Radio,
  // RadioGroup,
  Select,
  // SelectChangeEvent,
  // Switch,
  TextField,
  // alpha,
  // useTheme,
  Stack,
  Typography,
  IconButton,
  Modal,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Stepper,
  Step,
  StepLabel,
  Checkbox,
  Radio,
  Divider,
  Paper,
  styled,
  tableCellClasses,
} from '@mui/material';
// import React from 'react';
import { Box } from '@mui/system';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
//
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Iconify from 'src/components/iconify';
// import Label from 'src/components/label/Label';
import Editor from 'src/components/editor';
import _mock from 'src/_mock/_mock';
// import { m, AnimatePresence } from 'framer-motion';
import { useAuthContext } from 'src/auth/useAuthContext';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CloseIcon } from 'src/theme/overrides/CustomIcons';
import Scrollbar from 'src/components/scrollbar';
import Upload from 'src/components/upload/Upload';
import {
  //  TreeItem, TreeItemProps, TreeView,
  LoadingButton,
} from '@mui/lab';
import FormProvider, {
  RHFCheckbox,
  RHFRadioGroup,
  RHFSelect,
  RHFTextField,
} from 'src/components/hook-form';
// import { fDate } from 'src/utils/formatTime';
import { useSettingsContext } from 'src/components/settings';
import FileThumbnail from 'src/components/file-thumbnail';
import { useSnackbar } from 'src/components/snackbar';

// import dayjs from 'dayjs';

// import { varFade } from 'src/components/animate';
import axios from 'src/utils/axios';
import DocViewer, {
  DocViewerRenderers,
  IDocument,
  DocViewerRef,
  // IConfig,
} from '@cyntler/react-doc-viewer';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { HOST_API_KEY } from 'src/config-global';
// import StageOne, { StageOneHandle } from './StagePopup';
import Tooltip from '@mui/material/Tooltip';
import FileAction from './FileAction';
import FileNewFolderDialog from './FileNewFolderDialog';
import JobCompleteDialog from './JobComplete';
import ResponsiveDialog from '../model';
import SendEmailDialog from './SendMail';
import SelectTemplate from './SelectTemplate';

interface ViewException {
  handleClose: () => void;
  data: JobRecord;
}
interface Signature {
  UserID: number;
  Signature: string;
  EntryBy?: number;
}
interface RowStage {
  ID: number;
  JobRecordID: number;
  StageName: string;
  ChecklistName: string;
  QuestionID: number;
  Question: string;
  QueTopic: string;
  NaIncluded: string;
  Answer: string;
  NotesIncluded: string;
  Notes: string;
  ModifiedDate: string;
  ModifiedBy: number;
  UserFname: string;
  UserLname: string;
  UserEmail: string;
}

interface JobStatus {
  JobRecordID: number;
  JobNumber: string;
  JobStatus: string;
  JobRecordTimestamp: string;
  JobEntryBy: number;
}

function ExceptionView({ handleClose, data }: ViewException) {
  return (
    <Container>
      <DialogTitle>Job Exception: {data.JobRecordID}</DialogTitle>

      <Grid container direction="row" spacing={2} lg={12} sx={{ margin: 1 }}>
        <Grid item lg={6}>
          <FormLabel id="Notes">Reason</FormLabel>
          <TextareaAutosize
            style={{
              width: '100%',
              height: '160px',
              color: 'white',
              backgroundColor: '#212b36',
              borderColor: '#45505c',
            }}
            value={_mock.text.description(1)}
            color="primary"
          />
        </Grid>
        <Grid item lg={6}>
          <Stack>
            <Box>
              <FormLabel id="accessor">Job Assessor</FormLabel>
              <Select sx={{ width: '100%' }} label="accessor" value={10} variant="outlined">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>John Doe</MenuItem>
                <MenuItem value={20}>Double Storey</MenuItem>
                <MenuItem value={30}>Cannot Determine</MenuItem>
              </Select>
            </Box>
            <Box sx={{ width: '100%', marginTop: 3 }}>
              <FormLabel id="priority">Priority</FormLabel>
              <Select sx={{ width: '100%' }} label="priority" value="10" variant="outlined">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>High</MenuItem>
                <MenuItem value={20}>Double Storey</MenuItem>
                <MenuItem value={30}>Cannot Determine</MenuItem>
              </Select>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Grid>
        <Button
          variant="soft"
          color="primary"
          onClick={handleClose}
          sx={{ margin: 3, marginLeft: 100 }}
        >
          Save
        </Button>
        <Button variant="soft" color="error" sx={{ margin: 3 }}>
          Reprocess
        </Button>
        <Button variant="soft" color="secondary" onClick={handleClose} sx={{ margin: 3 }}>
          Back
        </Button>
      </Grid>
    </Container>
  );
}

// job number - editable
// job record id -- non editable
// job status -- editable select
// job floors - editable
// job email subject -- editable
// job prelim / final -- editable
// job rating -- editable
// job site addr -- editable
// job type - new / existing editable
// job dates -- non editable

type FormValuesProps = {
  JobAssessorID: string;
  JobCode: string;
  JobNumber: string;
  JobType: string;
  JobDwelling: string;
  JobFloors: number;
  JobStatus: string;
  JobEmailSubject: string;
  JobPrelimFinal: string;
  JobRating: string;
  JobSiteAddr: string;
  JobAddrState: string;
  JobIsUrgent: boolean;
  JobPostAssessmentFlag: boolean;
  JobRequestorID: string;
  AdditionalJobCode: string;
  JobSlaTime: string;
  AWOH_VALID: string;
  Ncc_Type: string;
  JobSlaMessage: string;
  JobStage: string;
};

interface JobRecord {
  JobRecordID: number;
  JobNumber: string;
  EmailRecordID: number;
  JobEmailMsgID: number;
  JobEmailSubject: string;
  JobFloors: number;
  JobRequestorID: number;
  JobSiteAddr: string;
  JobAddrState: string;
  JobIsUrgent: string;
  JobCode: string;
  JobType: string;
  JobPrelimFinal: string;
  JobAssessorID: number;
  JobReprocessFlag: boolean;
  JobDwelling: string;
  JobStatus: string;
  JobProcessedFlag: string;
  JobProcessedAt: string | null;
  JobCreatedAt: string;
  JobRating: string;
  JobPostAssessmentFlag: boolean;
  UserFname: string;
  UserLname: string;
  UserEmail: string;
  AWOH_VALID: string;
  Ncc_Type: string;
  JobStage: string;
}

interface JobStageData {
  ID: number;
  JobRecordID: number;
  StageName: string;
  ChecklistName: string;
  QuestionID: number;
  Question: string;
  QueTopic: string;
  NaIncluded: string;
  Answer: string;
  NotesIncluded: string;
  Notes: string;
  ModifiedBy: number;
  UserFname: string;
  UserLname: string;
  UserEmail: string;
}

interface Assessor {
  ID: number;
//  UserUsername: string;
  UserFname: string;
  UserLname: string;
//  UserGender: string;
  UserEmail: string;
//  UserPhone: string;
//  UserStreetAddr: string;
//  UserState: string;
//  UserZipcode: string;
//  UserCountry: string;
//  UserStatus: string;
//  UserProfilePic: string | null;
//  UserRole: string;
//  UserPwdID: number;
//  UserTimezone: string | null;
//  UserLastLogin: string | null;
//  UserCreatedAt: string;
//  UserCreatedBy: number;
//  UserToken: string | null;
// UserRefreshToken: string | null;
//  UserOccupency: number;
}

interface JobAttachment {
  JobAttachID: number;
  EmailAttachID: number;
  JobRecordID: number;
  JobAttachName: string;
  JobAttachExtension: string;
  JobAttachType: string;
  JobAttachCategory: 'public' | 'confidential';
  JobAttachLoc: string;
  JobAttachSize: number;
  CreatedBy: number;
  CreatedAt: string; // You can use a proper date type here if needed
}

interface EmailData {
  ID: number;
  JobRecordID: number;
  EmailMsgID: string;
  EmailSubject: string;
  EmailSummary: string;
  EntryBy: number;
  CreatedDate: string;
  UserFname: string;
  UserLname: string;
  UserProfilePic: string;
}

interface JobComment {
  ID: number;
  JobRecordID: number;
  SequenceMsg: number;
  CommentMsg: string;
  UserID: number;
  CreatedBy: number;
  CreatedAt: string;
  UserFname: string;
  UserLname: string;
  UserProfilePic: string;
}

type Requestors = {
  ID: number;
  ReqName: string;
  ReqSubloc: string;
  ReqCreatedDatetime: string;
  ReqEmailDomain: string;
  ReqContactPhone: string;
  ReqStatus: string;
  IsDefaultFinal: string;
  IsPrelimFinalMentionedSubject: string;
  ReqType: string;
  ReqJobTypes: string;
  ReqEmailResponse: string;
  PeopleListed: string;
  Notes: string;
};

// interface Signature {
//   UserID: number;
//   Signature: string;
//   EntryBy?: number;
// }

const predefinedValues = [
  'NSW - New South Wales',
  'VIC - Victoria',
  'QLD - Queensland',
  'WA - Western Australia',
  'SA - South Australia',
  'TAS - Tasmania',
  'ACT - Australian Capital Territory',
];

export default function ViewJobs() {
  const [createdDate, setCreatedDate] = useState<Date | null>(new Date());
  const { user } = useAuthContext();
  const userrole = `${user?.UserRole ?? ''}`;
  const { enqueueSnackbar } = useSnackbar();
  const ref = useRef<HTMLInputElement | null>(null);
  const [selectedRadio, setSelectedRadio] = useState<{ [key: string]: string | null }>({});

  const [nextStageVal, setnextStageVal] = useState('fail');
  const [initiatingAssessorID, setInitiatingAssessorID] = useState(null);
  const [rowStage, setRowStage] = useState<RowStage[]>([]);
  const [loading, setLoading] = useState(false);
  const [editChk, setEditChk] = useState(false);
  const [jobRecordID, setJobRecordID] = useState<string>(''); // State for jobRecordID
  const [stageName, setStageName] = useState<string>('');
  const [cName, setCName] = useState<string>('');
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [jobStage, setJobStage] = useState('');
  const [data, setData] = useState<JobRecord | null>(null);
  const [selectedStage, setSelectedStage] = useState('');
  const tableBodyRef = useRef<HTMLDivElement>(null);
  let activeAssessorsApi = '';
  if (data?.JobStage) {
    //    activeAssessorsApi = `/core/getActiveAssessors?StageName=${data?.JobStage}`;
    activeAssessorsApi = `/core/getActiveAssessors`;
  }

  const RedRadio = styled(Radio)(({ theme }) => ({
    '&.Mui-checked': {
      color: 'red',
    },
  }));

  const validateRadioButtons = useCallback(() => {
    const isValid = rowStage.every((row) => {
      const answer = selectedRadio[row.QuestionID.toString()] || '';
      return answer === 'Y' || answer === 'N';
    });
    setIsSaveButtonDisabled(!isValid);
  }, [selectedRadio, rowStage]);

  useEffect(() => {
    validateRadioButtons();
  }, [selectedRadio, rowStage, validateRadioButtons]);

  useEffect(() => {
    validateRadioButtons();
  }, [validateRadioButtons]);

  useEffect(() => {
    const initialSelectedRadio: { [key: string]: string | null } = {};
    rowStage.forEach((row) => {
      initialSelectedRadio[row.QuestionID.toString()] = row.Answer;
    });
    setSelectedRadio(initialSelectedRadio);
  }, [rowStage]);

  useEffect(() => {
    const initialSelectedRadio: { [key: string]: string | null } = {};
    rowStage.forEach((row) => {
      initialSelectedRadio[row.QuestionID.toString()] = row.Answer;
    });
    setSelectedRadio(initialSelectedRadio);
  }, [rowStage]);

  const handleRadioChange = (questionIndex: string, value: string) => {
    setSelectedRadio((prev) => {
      const newSelectedRadio = { ...prev, [questionIndex]: value };
      return newSelectedRadio;
    });

    setRowStage((prev) =>
      prev.map((row) =>
        row.QuestionID.toString() === questionIndex ? { ...row, Answer: value } : row
      )
    );
  };

  const saveChanges = async () => {
    const updatedRowStage = rowStage?.map((row) => {
      if (!row.Answer) {
        return { ...row, NaIncluded: '' };
      }
      return row;
    });

    setRowStage(updatedRowStage);

    console.log('Save changes for update checklist = ', updatedRowStage);
    console.log('User info :', user)
    // if (!isSaveButtonDisabled) {
    // console.log('Test test', rowStage);
    setLoading(true);
    try {
      const response = await axios.post(
        `/core/jobUpdateChecklist?user_id=${user?.ID}`,
        updatedRowStage
      );
      enqueueSnackbar('Changes saved successfully', { variant: 'success' });
      setEditChk(true);
      VerifyStageChecklist();
    } catch (error) {
      console.error('Error while saving:', error);
      enqueueSnackbar('Failed to save changes', { variant: 'error' });
    } finally {
      setLoading(false);
      handleClose();
      getJobChecklists();
    }
    // }
  };

  const handleFillChecklistClick = () => {
    fetchStageData(selectedStage).then(() => {
      handleOpen();
    });
  };

  const renderRows = (rows: RowStage[]) =>
    rows.map((row, index) => {
      const isEditable =
        row.Answer.toLowerCase() === 'na' ||
        row.Answer.toLowerCase() === '' ||
        selectedRadio[row.QuestionID.toString()]?.toLowerCase() === 'na';
      const showAnswersAndNotes = row.QueTopic.toLowerCase() !== 't';
      const isTopicT = row.QueTopic.toLowerCase() === 't';
      let notesIncl = false;
      let naIncl = false;
      if (row.NotesIncluded.toLowerCase() === 'no') {
        notesIncl = false;
      } else {
        notesIncl = true;
      }

      if (row.NaIncluded.toLowerCase() === 'no') {
        naIncl = false;
        // console.log('Na included =', naIncl);
      } else {
        naIncl = true;
        // console.log('Na included =', naIncl);
      }

      return (
        <React.Fragment key={index}>
          {isTopicT && (
            <TableRow>
              <TableCell colSpan={8}>
                <Divider />
              </TableCell>
            </TableRow>
          )}
          <TableRow
            key={index}
            // style={{
            //   backgroundColor: isTopicT ? '#8ba1af' : 'inherit',
            //   fontSize: isTopicT ? 'larger' : 'normal',
            // }}
          >
            <TableCell colSpan={1}>{row.QuestionID}</TableCell>
            <TableCell
              align="left"
              colSpan={3}
              sx={{
                backgroundColor: isTopicT ? '#2d6169' : 'inherit',
                color: isTopicT ? 'white' : 'inherit',
              }}
            >
              {row.Question}
            </TableCell>
            {showAnswersAndNotes ? (
              <>
                <TableCell style={{ width: '5%' }}>
                  <Radio
                    checked={selectedRadio[row.QuestionID.toString()] === 'Y'}
                    onChange={() => handleRadioChange(row.QuestionID.toString(), 'Y')}
                    value=""
                    name={`radio-${row.QuestionID}`}
                    inputProps={{ 'aria-label': 'Yes' }}
                    // disabled={!isEditable}
                  />
                </TableCell>
                <TableCell style={{ width: '5%' }}>
                  <RedRadio
                    checked={selectedRadio[row.QuestionID.toString()] === 'N'}
                    onChange={() => handleRadioChange(row.QuestionID.toString(), 'N')}
                    value=""
                    name={`radio-${row.QuestionID}`}
                    inputProps={{ 'aria-label': 'No' }}
                    // disabled={!isEditable}
                  />
                </TableCell>
                {/* {naIncl && (
                  <TableCell style={{ width: '5%' }}>
                    <Radio
                      checked={selectedRadio[row.QuestionID.toString()] === 'NA'}
                      onChange={() => handleRadioChange(row.QuestionID.toString(), 'NA')}
                      value="NA"
                      name={`radio-${row.QuestionID}`}
                      inputProps={{ 'aria-label': 'N/A' }}
                    />
                  </TableCell>
                )} */}
                <TableCell sx={{ width: '5%', visibility: naIncl ? 'visible' : 'hidden' }}>
                  <Radio
                    checked={selectedRadio[row.QuestionID.toString()] === 'NA'}
                    onChange={() => handleRadioChange(row.QuestionID.toString(), 'NA')}
                    value=""
                    name={`radio-${row.QuestionID}`}
                    inputProps={{ 'aria-label': 'N/A' }}
                  />
                </TableCell>
              </>
            ) : null}
            {notesIncl && (
              <TableCell align="right" colSpan={3}>
                {showAnswersAndNotes && (
                  <TextField
                    variant="outlined"
                    // fullWidth
                    multiline
                    rows={3}
                    placeholder="Enter notes here"
                    sx={{ width: '85%' }}
                    // sx={{ minWidth: '300px' }}
                    value={row.Notes || ''}
                    // disabled={!notesIncl}
                    onChange={(e) => handleNotesChange(row.QuestionID, e.target.value)}
                  />
                )}
              </TableCell>
            )}
          </TableRow>
        </React.Fragment>
      );
    });

  const handleNotesChange = (questionID: number, newNotes: string) => {
    setRowStage((prevRows) =>
      prevRows.map((row) => {
        if (row.QuestionID === questionID) {
          return { ...row, Notes: newNotes };
        }
        return row;
      })
    );
  };

  const isButtonDisabled =
    !selectedStage || ['Audit', 'RPT', 'BX + WOH', '', 'workflow'].includes(selectedStage);

  const assessorAllowedStatuses = [
    'assigned',
    'assessment started',
    'workflow to action',
    'cancelled',
    'on hold',
    'workflow to action',
  ];
  const assessorNotAllowedStatuses = [
    'new',
    'exception',
    'marked as complete',
    'pending validation',
  ];

  const otherStatus = ['assessment started', 'workflow to action'];

  const StyledRoot = styled('div')(({ theme }) => ({
    position: 'relative',
    padding: theme.spacing(0, 2),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.neutral,
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
    '&:hover': {
      zIndex: 9,
      position: 'relative',
      // boxShadow: theme.customShadows.z24,
      '& #mailActions': { opacity: 1 },
    },
  }));

  const [attachments, setAttachments] = useState<JobAttachment[] | null>(null);
  const [jobStatusProgress, setJobStatusProgress] = useState<any>(null);
  const [jobSlaTime, setJobSlaTime] = useState<any>(null);
  const [splitJobFlag, setSplitJobFlag] = useState(false);
  const [assessmentConfirmation, setAssessmentConfirmation] = useState(false);
  const [ncc_type, setNccType] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  // const [recheckJobFlag, setRecheckJobFlag] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const editQueryParam = queryParams.get('edit');
  const edit = editQueryParam ? !JSON.parse(editQueryParam.toLowerCase()) : true;
  const [assessor, setAssessor] = useState<Assessor[] | null>(null);
  const [allAssessor, setAllAssessor] = useState<Assessor[] | null>(null);
  //  const [jobStatusDate, setJobStatusDate] = useState<JobStatus[]>([]);
  const [requestors, setRequestors] = useState<Requestors[] | null>(null);
  const [jobStageData, setJobStageData] = useState<JobStageData[] | null>(null);
  const [jobCodes, setJobCodes] = useState<JobCodes[] | null>(null);
  // const [requestorSubLocation, setRequestorSubLocation] = useState<any>(null);

  const CommentSchema = Yup.object().shape({
    // JobAssessorID: Yup.string().required('Assessor is required'),
    // JobType: Yup.string().required('Job Type is required'),
    // JobDwelling: Yup.string().required('Job Dwelling is required'),
    JobDwelling: Yup.string().when('jobStatus', {
      is: (status: string) => {
        const normalizedStatus = status ? status.toLowerCase() : '';
        return normalizedStatus === 'cancelled' || normalizedStatus === 'on hold';
      },
      then: Yup.string().required('Job Dwelling is required to be filled'),
      otherwise: Yup.string(), // or Yup.string().nullable() if you want it to be optional
    }),
    // JobIsUrgent: Yup.string().required('Job Type is required'),
    // JobFloors: Yup.string().required('Job Floor is required'),
    JobEmailSubject: Yup.string().required('Email subject is required'),
    // JobPrelimFinal: Yup.string().required('Prelim or final is required'),
    JobPrelimFinal: Yup.string().when('jobStatus', {
      is: (status: string) => {
        const normalizedStatus = status ? status.toLowerCase() : '';
        return normalizedStatus === 'cancelled' || normalizedStatus === 'on hold';
      },
      then: Yup.string().required('Prelim or final is required'),
      otherwise: Yup.string(), // or Yup.string().nullable() if you want it to be optional
    }),
    // JobRating: Yup.string().required('Job Rating is required'),
    // JobAddrState: Yup.string().required('State is required'),
    JobStatus: Yup.string().required('Status is required'),
    // JobSiteAddr: Yup.string().required('Address is required'),
    JobSiteAddr: Yup.string().when('jobStatus', {
      is: (status: string) => {
        const normalizedStatus = status ? status.toLowerCase() : '';
        return normalizedStatus === 'cancelled' || normalizedStatus === 'on hold';
      },
      then: Yup.string().required('Address is required'),
      otherwise: Yup.string(), // or Yup.string().nullable() if you want it to be optional
    }),
    // JobRequestorID: Yup.string().required('Requestor is required'),
    JobRequestorID: Yup.string().when('jobStatus', {
      is: (status: string) => {
        const normalizedStatus = status ? status.toLowerCase() : '';
        return normalizedStatus === 'cancelled' || normalizedStatus === 'on hold';
      },
      then: Yup.string().required('Requestor is required to be selected'),
      otherwise: Yup.string(), // or Yup.string().nullable() if you want it to be optional
    }),
    // AWOH_VALID: Yup.string().required('AWOH Status is required'),
    // Ncc_Type: Yup.string().required('NCC_Type is required.Choose either NCC 2019 or NCC 2022'),
    Ncc_Type: Yup.string().when('jobStatus', {
      is: (status: string) => {
        const normalizedStatus = status ? status.toLowerCase() : '';
        return normalizedStatus === 'cancelled' || normalizedStatus === 'on hold';
      },
      then: Yup.string().required('NCC_Type is required. Choose either NCC 2019 or NCC 2022'),
      otherwise: Yup.string(), // or Yup.string().nullable() if you want it to be optional
    }),
    JobStage: Yup.string().required('Job Stage is required'),
  });
  const defaultValues = {
    JobAssessorID: '',
    JobNumber: '',
    JobType: '',
    JobDwelling: '',
    // JobIsUrgent: false,
    // JobPostAssessmentFlag: '',
    JobFloors: 0,
    JobEmailSubject: '',
    JobPrelimFinal: '',
    JobRating: '',
    JobStatus: '',
    JobAddrState: '',
    JobRequestorID: '',
    JobSiteAddr: '',
    JobCode: '',
    AWOH_VALID: '',
    Ncc_Type: '',
    JobStage: '',
  };
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });
  const {
    // reset,
    handleSubmit,
    setValue,
    watch,
    // formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    axios
      .get(`/core/getAllAssessors`)
      .then((response) => {
        setAllAssessor(response.data as Assessor[]);
      })
      .catch((error) => {
        console.error('Error fetching getActiveAssessors:', error);
      });
  }, []);

  const handleStageChange = (job_stage: string) => {
    const temp_job_stage = job_stage;
    setSelectedStage(temp_job_stage);
    setValue('JobStage', job_stage);
    console.log('Selected State = ', job_stage);
    axios
      .get(`/core/getActiveAssessors?StageName=${job_stage}`)
      .then((response) => {
        setAssessor(response.data as Assessor[]);
      })
      .catch((error) => {
        console.error('Error fetching getActiveAssessors:', error);
      });
  };

  const openAssessmentConfirmation = () => {
    setAssessmentConfirmation(true);
  };

  const [comments, setComments] = useState<JobComment[] | null>([]);
  const fetchComments = useCallback(() => {
    console.log('Fetching comments');
    if (id) {
      axios
        .get(`/core/getAllComments?Id=${id}`)
        .then((response) => {
          setComments(response.data as JobComment[]);
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: 'error' });
        });
    }
  }, [id, enqueueSnackbar]);

  const VerifyStageChecklist = useCallback(() => {
    axios
      .get(`/core/verifyJobChecklist?job_id=${id}&user_id=${user?.ID}`)
      .then((response) => {
        setnextStageVal(response.data);
      })
      .catch((error) => {
        console.error('Error fetching ChecklistVerification:', error);
      });
  }, [id, user]);

  const getAttachmentInfo = useCallback(() => {
    if (id) {
      axios
        .get(`/core/getAllAttachments?Id=${id}`)
        .then((response) => {
          // console.log("getAllAttachments :" ,response.data);
          setAttachments(response?.data as JobAttachment[]);
        })
        .catch((error) => {
          console.error('Error fetching getAllAttachments:', error);
        });
    }
  }, [id]);

  const getEmailList = () => {
    axios
      .get(`/core/getAllEmailForJob?Id=${id}`)
      .then((response) => {
        setEmails(response.data);
        console.table(response.data);
      })
      .catch((error) => {
        console.error('Error fetching getAllEmailForJob:', error);
      });
  };

  const startAssessment = () => {
    if (data !== null) {
      // console.log('Assessor id = ', data?.JobAssessorID);
      // console.log(' job stage = ', data?.JobStage);
      // Make API call for start assessmentto backend here
      axios
        .post(
          `/core/assessmentStarted?jobRecordId=${data.JobRecordID}&jobAssessorId=${data?.JobAssessorID}&jobStage=${data?.JobStage}&UserId=${user?.ID}`
        )
        .then((response) => {
          getJobInfo();
          getJobSlaTime();
          // getJobStatusDateByJobId();
          enqueueSnackbar(response.data);
          // console.log('Response from backend assessmentStarted :', response.data);
          getJobStatusByProgress();
          setAssessmentConfirmation(false);
        })

        .catch((error) => {
          enqueueSnackbar(error, { variant: 'error' });
          console.error('Error for assessmentStarted:', error);
        });
    }
  };

  type JobCodes = {
    ID: number;
    JobCode: string;
  };

  useEffect(() => {
    axios
      .get(`/core/getAllJobCode`)
      .then((response) => {
        setJobCodes(response.data as JobCodes[]);
      })
      .catch((error) => {
        console.error('Error fetching job codes:', error);
      });
  }, [id]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const getJobStatusDateByJobId = useCallback(() => {
  //   axios
  //     .get(`/core/GetStatusDateByJobId?Id=${id}`)
  //     .then((response) => {
  //       console.log('Job status date 1 = ', response.data);
  //       setJobStatusDate(response.data);
  //       console.log('Job status date = ', response.data);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching status date:', error);
  //     });
  // }, [id]);

  // const getJobStatusDateByJobId = useCallback(() => {
  //   axios
  //     .get(`/core/GetStatusDateByJobId?Id=${id}`)
  //     .then((response) => {
  //       console.log('Job status date 1 = ', response.data);
  //       setJobStatusDate(response.data);
  //       console.log('Job status date = ', response.data);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching status date:', error);
  //     });
  // }, [id]);

  // useEffect(() => {
  //   const getJobStatusDateByJobId = () => {
  //     axios
  //       .get(`/core/GetStatusDateByJobId?Id=${id}`)
  //       .then((response) => {
  //         console.log('Job status date 1 = ', response.data);
  //         setJobStatusDate(response.data);
  //         console.log('Job status date = ', response.data);
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching status date:', error);
  //       });
  //   };

  //   getJobStatusDateByJobId();
  // }, [id]);
  // const getJobStatusDateByJobId = useCallback(() => {
  //   axios
  //     .get(`/core/GetStatusDateByJobId?Id=${id}`)
  //     .then((response) => {
  //       console.log('Job status date 1 = ', response.data);
  //       setJobStatusDate(response.data);
  //       console.log('Job status date = ', response.data);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching status date:', error);
  //     });
  // }, [id]);

  const getJobException = useCallback(() => {
    axios
      .get(`/core/getJobException?Id=${id}`)
      .then((response) => {
        //        console.log('Job status date 1 = ', response.data);
        setException(response.data.ExceptionReason);

        //        console.log('Job status date = ', response.data);
      })
      .catch((error) => {
        console.error('Error fetching getJobException:', error);
      });
  }, [id]);
  // useEffect(() => {
  //   getJobStatusDateByJobId();
  // }, [getJobStatusDateByJobId]);

  useEffect(() => {
    fetchComments();
    getAttachmentInfo();
    // getJobStatusDateByJobId();
    // getStatusDateByJobId();
  }, [fetchComments, getAttachmentInfo]);
  const getJobInfo = useCallback(() => {
    if (id) {
      let jobData :any;
      axios
        .get(`/core/getJob?Id=${id}`)
        .then((response) => {
// Arpan change for Assessor name of inactive
//          setData(response.data as JobRecord);
//          console.log('Current job data = ', response.data);
          jobData = response.data as JobRecord;
          setData(jobData);
          console.log('Current job data = ', jobData);
          
          if (response.data.JobAssessorID === 0) {
            setValue('JobAssessorID', '0');
          } else {
            setValue('JobAssessorID', response.data.JobAssessorID);
          }
          setJobStage(response.data.JobStage);
          setValue('JobType', response.data.JobType);
          setSelectedStage(response?.data?.JobStage);
          // setValue('Ncc_Type', response.data.Ncc_Type);
          setValue('JobNumber', response.data.JobNumber);
          setValue('JobDwelling', response.data.JobDwelling);
          // setValue('JobSlaMessage', response.data.JobSlaMessage);
          if (response.data.JobIsUrgent) {
            setJobIsUrgent(true);
          } else {
            setJobIsUrgent(false);
          }
          if (response.data.JobPostAssessmentFlag) {
            setJobPostAssessmentFlag(true);
          } else {
            setJobPostAssessmentFlag(false);
          }
          setValue('JobIsUrgent', response.data.JobIsUrgent !== '');
          setValue('JobPostAssessmentFlag', response.data.JobPostAssessmentFlag !== '');
          setValue('JobCode', response.data.JobCode);
          setValue('JobFloors', response.data.JobFloors === 0 ? '0' : response.data.JobFloors);
          setValue('JobStatus', response.data.JobStatus);
          setValue('JobStage', response.data.JobStage);
          setValue('JobEmailSubject', response.data.JobEmailSubject);
          setValue('JobPrelimFinal', response.data.JobPrelimFinal);
          if (response.data.JobRating === 0.0) {
            setValue('JobRating', '0');
          } else {
            setValue('JobRating', response.data.JobRating);
          }
          setInputValue(response.data.JobAddrState);
          setValue('JobAddrState', response.data.JobAddrState);
          setValue('JobSiteAddr', response.data.JobSiteAddr);
          // setValue('AWOH_VALID', response.data.AWOH_VALID);
          setValue('JobRequestorID', response.data.JobRequestorID);
          if (response.data.JobStatus === 'exception') {
            getJobException();
          }
          VerifyStageChecklist();
        })
        .catch((error) => {
          console.error('Error fetching getJob:', error);
        })
        .then(() => {
          console.error('Calling get all assessors = ', activeAssessorsApi);
          axios
            .get(activeAssessorsApi)
            .then((assessorResponse) => {
//              setAssessor(response.data as Assessor[]);
                const assessors = assessorResponse.data as Assessor[];
                if (jobData.UserEmail) {
                  const assessorExists = assessors.some(
                    (assessor_temp) => assessor_temp.UserEmail === jobData.UserEmail
                  );
                  // If assessor with job-related email is not found, add it
                  if (!assessorExists) {
                    const newAssessor = {
                      UserFname: jobData.UserFname || '',
                      UserLname: jobData.UserLname || '',
                      ID: jobData.JobAssessorID,
                      UserEmail: jobData.UserEmail
                  };
                  assessors.push(newAssessor);
                  }
                }
                setAssessor(assessors);
            })
            .catch((error) => {
              console.error('Error fetching getActiveAssessors:', error);
            });
        });
    }
  }, [id, setValue, VerifyStageChecklist, getJobException, activeAssessorsApi]);

  // useEffect(() => {
  //   const matchedRequestor: Requestors | undefined = requestors?.find(
  //     (requestor: { ID: any }) => requestor.ID === data?.JobRequestorID
  //   );
  //   if (matchedRequestor) {
  //     setRequestorSubLocation(matchedRequestor.ReqSubloc);
  //   }
  // }, [data?.JobRequestorID, requestors]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'inheri',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: 'inherit',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const getJobChecklists = useCallback(() => {
    if (id) {
      axios
        .get(`/core/jobChecklists?job_id=${id}`)
        .then((response) => {
          const response_data = response?.data;
          // Sort data by ID in ascending order
          const sortedData = response_data.sort((a: any, b: any) => a.ID - b.ID);
          setJobStageData(sortedData);
        })
        .catch((error) => {
          console.error('Error fetching job stage data:', error);
        });
    }
  }, [id]);

  const [open, setOpen] = useState(false);

  const fetchStageData = useCallback(
    async (stage: string) => {
      setLoading(true);
      if (id) {
        try {
          const response = await axios.get(
            `/core/jobCreateChecklist?job_id=${id}&user_id=${user?.ID}`
          );
          const checklist_data = response.data;
          console.log('Get checklist response:', response.data);
          getJobChecklists();

          if (checklist_data.length > 0) {
            setRowStage(checklist_data);
            setJobRecordID(checklist_data[0].JobRecordID);
            setStageName(checklist_data[checklist_data.length - 1].StageName);
            setCName(checklist_data[0].ChecklistName);
          }

          const hasNonEmptyAnswer = checklist_data.some((item: any) => item.Answer !== '');
          setEditChk(hasNonEmptyAnswer);
        } catch (error) {
          console.error('Error for jobCreateChecklist:', error);
          if (open === true) {
            enqueueSnackbar(error?.error, { variant: 'error' });
          }
        } finally {
          setLoading(false);
        }
      }
    },
    [id, user?.ID, getJobChecklists, open, enqueueSnackbar]
  );

  useEffect(() => {
    if (selectedStage) {
      fetchStageData(selectedStage);
    }
  }, [fetchStageData, selectedStage]);

  useEffect(() => {
    getJobInfo();
    getJobChecklists();
    axios
      .get(`/core/getActiveRequestors`)
      .then((response) => {
        setRequestors(response.data as Requestors[]);
      })
      .catch((error) => {
        console.error('Error fetching active Requestors:', error);
      });
  }, [getJobChecklists, getJobInfo]);

  const NavigateToJobs = () => {
    navigate('/jobs');
  };

  // const handleException = () => {
  //   setOpen(true);
  // };
  const handleExceptionClose = () => {
    setOpen(false);
  };
  // useEffect(() => {
  //   if(jobStatusProgress){
  //     getJobStatusByProgress();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [jobStatusProgress]);

  const getJobStatusByProgress = useCallback(async () => {
    // console.log('Job id = ', id);
    try {
      const response = await axios.get(`/core/jobStatusProgress?Id=${id}`);
      const statusProgress = response.data;
      console.log('Status Progress:', statusProgress);
      setJobStatusProgress(statusProgress);
      statusProgress.map((status: any) => {
        if (status.JobStatus === 'split job') {
          setSplitJobFlag(true);
          console.log('Split job = ', splitJobFlag);
        }
        // if (status.JobStatus === 'reassess initiated') {
        //   setRecheckJobFlag(true);
        //   console.log('reassess initiated = ', recheckJobFlag);
        // }
        return null;
      });
    } catch (error) {
      console.error('Error fetching jobStatusProgress:', error);
    }
  }, [id, splitJobFlag]);
  // [id, splitJobFlag, recheckJobFlag]);

  //  const getJobSlaTime = async () => {
  //   console.log('Job id = ', id);
  //   try {
  //     const response = await axios.get(`/core/jobSlaTimeById?Id=${id}`);
  //     const jobSlaTimeObj = response.data;
  //     setValue('AdditionalJobCode', response.data?.JobAddJobcode);
  //     setJobSlaTime(jobSlaTimeObj);
  //     console.log('Job sla time = ', jobSlaTimeObj);
  //   } catch (error) {
  //     console.error('Error fetching job status:', error);
  //   }
  // };

  const [awoh, setAwoh] = useState('');
  const [ncc, setNcc] = useState('');
  const getJobSlaTime = useCallback(async () => {
    // console.log('Job id = ', id);
    try {
      const response = await axios.get(`/core/jobSlaTimeById?Id=${id}`);
      const jobSlaTimeObj = response.data;
      console.log('Job sla time data = ', jobSlaTimeObj);
      setValue('AdditionalJobCode', jobSlaTimeObj?.JobAddJobcode);
      setJobSlaTime(jobSlaTimeObj);
      setValue('AWOH_VALID', jobSlaTimeObj?.AWOH_VALID);
      setAwoh(jobSlaTimeObj?.AWOH_VALID);
      console.log('Value for awoh valid = ', awoh);
      setValue('Ncc_Type', jobSlaTimeObj?.Ncc_Type);
      setNccType(jobSlaTimeObj?.Ncc_Type);
      console.log('Value for ncc = ', ncc_type);
      setValue('JobSlaMessage', jobSlaTimeObj?.JobSlaMessage);
      // console.log('Job sla time = ', jobSlaTimeObj);
    } catch (error) {
      console.error('Error fetching job sla time:', error);
    }
  }, [awoh, id, ncc_type, setValue]);

  useEffect(() => {
    if (tableBodyRef.current) {
      tableBodyRef.current.scrollTop = tableBodyRef.current.scrollHeight;
    }
  }, [jobStatusProgress]); // Scroll whenever jobStatusProgress changes

  useEffect(() => {
    getJobStatusByProgress();
    getJobSlaTime();
  }, [getJobSlaTime, getJobStatusByProgress]);

  const prevstage = async () => {
    try {
      const convertedData = {
        ...data,
        JobFloors: data?.JobFloors.toString(),
        JobIsUrgent: data?.JobIsUrgent.toString(),
        JobPostAssessmentFlag: data?.JobPostAssessmentFlag.toString(),
        // JobRating: data?.JobRating.toString(), // Convert number to string
        JobRequestorID: data?.JobRequestorID.toString(),
        JobAssessorID: data?.JobAssessorID.toString(),
        Ncc_Type: ncc_type,
        JobSlaTime: formatJobSlaTime(jobSlaTime?.JobSlaTime?.toString()),
        AdditionalJobCode: jobSlaTime?.AdditionalJobCode?.toString(),
        JobSlaMessage: jobSlaTime?.JobSlaMessage?.toString(),
        AWOH_VALID: jobSlaTime?.AWOH_VALID?.toString(),
      };
      console.log('Data for previous stage = ', convertedData);
      console.log(`Job ID: ${id}, User ID: ${user?.ID}, data: ${JSON.stringify(convertedData)}`);
      await axios
        .post(`/core/Prevstage?job_id=${id}&user_id=${user?.ID}`, convertedData)
        .then((response) => {
          getJobStatusByProgress();
          getJobInfo();
          // getJobSlaTime();
          NavigateToJobs();
          enqueueSnackbar(response.data);
        });
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      console.error('Error for prevstage:', error);
    }
  };
  const nextStage = async () => {
    try {
      // console.log('Original data = ', data);
      const convertedData = {
        ...data,
        JobFloors: data?.JobFloors.toString(),
        JobIsUrgent: data?.JobIsUrgent.toString(),
        JobPostAssessmentFlag: data?.JobPostAssessmentFlag.toString(),
        // JobRating: data?.JobRating.toString(), // Convert number to string
        JobRequestorID: data?.JobRequestorID.toString(),
        JobAssessorID: data?.JobAssessorID.toString(),
        Ncc_Type: ncc_type,
        JobSlaTime: formatJobSlaTime(jobSlaTime?.JobSlaTime?.toString()),
        AdditionalJobCode: jobSlaTime?.AdditionalJobCode?.toString(),
        JobSlaMessage: jobSlaTime?.JobSlaMessage?.toString(),
        AWOH_VALID: jobSlaTime?.AWOH_VALID?.toString(),
      };

      // console.log('Data for next stage = ', convertedData);
      console.log(`Job ID: ${id}, User ID: ${user?.ID}, data: ${JSON.stringify(convertedData)}`);

      await axios
        .post(`/core/Nextstage?job_id=${id}&user_id=${user?.ID}`, convertedData)
        .then((response) => {
          getJobStatusByProgress();
          getJobInfo();
          // getJobSlaTime();
          NavigateToJobs();
          enqueueSnackbar(response.data);
        });
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      console.error('Error for Nextstage:', error);
    }
  };

  const splitJob = async () => {
    try {
      await axios.post(`/core/splitjob?job_id=${id}&user_id=${user?.ID}`).then((response) => {
        enqueueSnackbar(response.data);
      });
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      console.error('Error for splitting job:', error);
    }
  };

  const recheckJobInitiated = () => {
    navigate('/jobs');
    // console.log('Job data  = ', data);
    const assessorId = watch('JobAssessorID');
    const jobStatus = watch('JobStatus');
    // console.log('Assessor id= = = ', assessorId);
    // console.log('Job status= = = ', jobStatus);

    if (data !== null) {
      const recheckData = data as any;
      recheckData.JobRecordID = String(recheckData?.JobRecordID);
      recheckData.JobFloors = String(recheckData?.JobFloors);
      recheckData.JobRequestorID = String(recheckData?.JobRequestorID);
      recheckData.JobAssessorID = String(assessorId);
      recheckData.JobRating = String(recheckData?.JobRating);
      recheckData.JobStatus = jobStatus;
      recheckData.JobCode = 'Recheck';
      // console.log('Recheck data =', recheckData);
      axios
        .post(`/core/recheckjob?job_id=${id}`, data)
        .then((response) => {
          getJobInfo();
          getJobSlaTime();
          // getJobStatusDateByJobId();
          enqueueSnackbar(response.data);
          // console.log('Response from backend recheck job :', response.data);
          getJobStatusByProgress();
        })

        .catch((error) => {
          enqueueSnackbar(error, { variant: 'error' });
          console.error('Error for reassess job initiation:', error);
        });
    }
  };
  const recheckJobStarted = () => {
    // console.log('Job data  = ', data);
    // console.log('Recheck Started');
    const assessorId = watch('JobAssessorID');
    const jobStatus = watch('JobStatus');
    // console.log('userid===', user?.UserID);
    // console.log('Assessor id= = = ', assessorId);
    // console.log('Job status= = = ', jobStatus);

    if (data !== null) {
      const recheckData = data as any;
      recheckData.JobRecordID = String(recheckData?.JobRecordID);
      recheckData.JobFloors = String(recheckData?.JobFloors);
      recheckData.JobRequestorID = String(recheckData?.JobRequestorID);
      recheckData.JobAssessorID = String(assessorId);
      recheckData.JobRating = String(recheckData?.JobRating);
      recheckData.JobStatus = 'reassess started';
      recheckData.JobCode = 'Recheck';
      // console.log('Recheck started data =', recheckData);
      // console.log('job id ', id);
      axios
        .post(`/core/recheckjobstarted?jobId=${id}`, data)
        .then((response) => {
          getJobInfo();
          getJobSlaTime();
          // getJobStatusDateByJobId();
          enqueueSnackbar(response.data);
          console.log('Response from backend recheck job started :', response.data);
          getJobStatusByProgress();
        })

        .catch((error) => {
          enqueueSnackbar(error, { variant: 'error' });
          console.error('Error for recheck job started:', error);
        });
      setInitiatingAssessorID(null);
    }
  };

  // const recheckjobcompleted = () => {
  //   console.log('Job data  = ', data);
  //   const assessorId = watch('JobAssessorID');
  //   const jobStatus = watch('JobStatus');
  //   console.log('Assessor id= = = ', assessorId);
  //   console.log('Job status= = = ', jobStatus);

  //   if (data !== null) {
  //     const recheckData = data as any;
  //     recheckData.JobRecordID = String(recheckData?.JobRecordID);
  //     recheckData.JobFloors = String(recheckData?.JobFloors);
  //     recheckData.JobRequestorID = String(recheckData?.JobRequestorID);
  //     recheckData.JobAssessorID = String(assessorId);
  //     recheckData.JobRating = String(recheckData?.JobRating);
  //     recheckData.JobStatus = 'reassess completed';
  //     recheckData.JobCode = 'Recheck';
  //     console.log('Recheck data =', recheckData);
  //     axios
  //       .post(`/core/recheckjobcompleted?jobId=${id}`, data)
  //       .then((response) => {
  //         getJobInfo();
  //         getJobSlaTime();
  //         // getJobStatusDateByJobId();
  //         enqueueSnackbar(response.data);
  //         console.log('Response from backend recheck job Completed :', response.data);
  //         getJobStatusByProgress();
  //       })

  //       .catch((error) => {
  //         enqueueSnackbar(error, { variant: 'error' });
  //         console.error('Error for recheck job Completion:', error);
  //       });
  //   }
  // };

  // const [files, setFiles] = useState<(File | string)[]>([]);

  const [JobIsUrgent, setJobIsUrgent] = useState(false);
  const [JobPostAssessmentFlag, setJobPostAssessmentFlag] = useState(false);

  const handleCheckboxChange = (event: { target: { checked: any } }) => {
    setJobIsUrgent(event.target.checked);
    // console.log('Job is urgent:', JobIsUrgent);
  };
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    // console.log('Input value = ', inputValue);
    const filteredSuggestions = predefinedValues.filter((option) =>
      option.toLowerCase().includes(value.toLowerCase())
    );
    // console.log('Filter suggetions = ', filteredSuggestions);
    setSuggestions(filteredSuggestions);
    setShowSuggestions(true);
  };

  const showSuggestionsList = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowSuggestions(true);
  };

  const handleSuggestionSelect = (value: string) => {
    setInputValue(value);
    setSuggestions([]);
  };

  const validateJobSlaTime = (selectedDate: Date): boolean => {
    const currentDate = new Date();
    const maxAllowedDate = new Date();
    maxAllowedDate.setDate(currentDate.getDate() + 7);
    return new Date(selectedDate) > maxAllowedDate;
  };
  const formatJobSlaTime = (jSla: any) => {
    if (!jSla) return '';

    // Assuming jobSlaTime is a string in the format "yyyy-mm-dd HH:MM:SS"
    const dateParts = jSla.split(' ')[0].split('-');
    const timePart = jSla.split(' ')[1];

    // Rearrange the date parts to "dd-mm-yyyy"
    const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;

    // Combine the formatted date with the time part
    return `${formattedDate} ${timePart}`;
  };
  const onSubmit = async (dat: FormValuesProps) => {
    try {
      dat.JobIsUrgent = JobIsUrgent;
      await CommentSchema.validate(dat, { abortEarly: false });
      const values = dat as any;
      dat.JobSlaTime = formatDate(jobSlaTime.JobSlaTime);
      values.JobSlaTime = dat.JobSlaTime;
      values.JobCreatedAt = data?.JobCreatedAt;
      values.JobFloors = String(dat?.JobFloors);
      if (!data?.JobCreatedAt) {
        enqueueSnackbar('Job Created At date is required!', { variant: 'error' });
        return;
      }
      const temp_rating = Number(dat?.JobRating);
      if(temp_rating < 0 || temp_rating > 10){
        enqueueSnackbar('Please Enter Valid Rating', { variant: 'error' });
        return;
      }
      const jobCreatedAt = new Date(data.JobCreatedAt);

      if (new Date(jobSlaTime.JobSlaTime) < jobCreatedAt) {
        enqueueSnackbar('Job SLA time cannot be earlier than the Job Created date!', {
          variant: 'error',
        });
        return;
      }
      if (validateJobSlaTime(jobSlaTime.JobSlaTime)) {
        enqueueSnackbar('Job SLA time cannot be more than 7 days from the current date!', {
          variant: 'error',
        });
        return;
      }
      if (dat.JobIsUrgent) {
        values.JobIsUrgent = 'urgent';
      } else {
        values.JobIsUrgent = '';
      }
      values.JobRating = Number(dat.JobRating);
      // values.JobAssessorID = Number(dat.JobAssessorID);
      // console.log('Values to backend = ', values);
      let assessor_flag = false;
      // console.log('Assessor Id = ', dat.JobAssessorID);
      // console.log('Status = ', dat.JobStatus);
      if (dat.JobStatus === 'new' || dat.JobStatus === 'exception') {
        if (dat.JobAssessorID !== '0' && dat.JobAssessorID !== null) {
          enqueueSnackbar('Cannot Assign Assessor in New or Exception Status', {
            variant: 'error',
          });
          assessor_flag = true;
        }
      }

      if (
        dat.JobStatus !== 'new' &&
        dat.JobStatus !== 'exception' &&
        dat.JobStatus !== 'cancelled' &&
        dat.JobStatus !== 'on hold'
      ) {
        if (dat.JobAssessorID === '0' || dat.JobAssessorID === null) {
          enqueueSnackbar('Assessor required to be mapped to Job', {
            variant: 'error',
          });
          assessor_flag = true;
        }
      }

      if (userrole === 'Assessor') {
        if (
          dat.JobStatus === 'new' ||
          dat.JobStatus === 'pending validation' ||
          dat.JobStatus === 'marked as complete' ||
          dat.JobStatus === 'exception'
        ) {
          enqueueSnackbar(
            'You cannot update Job with Assessor Role to New | Pending Validation | Marked as Complete status.',
            {
              variant: 'error',
            }
          );
          assessor_flag = true;
        }
      }

      // if (userrole === 'Workflow') {
      //   if (dat.JobStatus === 'workflow to action' || dat.JobStatus === 'assessment started') {
      //     enqueueSnackbar(
      //       'You cannot update Job with Workflow Role to Assessment Started | Workflow to Action status.',
      //       {
      //         variant: 'error',
      //       }
      //     );
      //     assessor_flag = true;
      //   }
      // }

      if (assessor_flag === false) {
        values.JobAssessorID = String(dat.JobAssessorID);
        values.JobAddrState = inputValue;
        console.log('Job data to submit = ', values);
        axios
          .post(`/core/updateJobtables?Id=${id}&UserId=${user?.ID}`, values)
          .then((response) => {
            enqueueSnackbar(response.data);
            getJobStatusByProgress();
            getJobInfo();
            getJobSlaTime();
            getJobChecklists();
            fetchStageData(selectedStage);
          })
          .catch((error) => {
            enqueueSnackbar(error, { variant: 'error' });
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const docViewerRef = useRef<DocViewerRef>(null);

  const [expanded, setExpanded] = useState<string | false>(false);
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const handleOpenUploadFile = (type: string) => {
    setFileType(type);
    setOpenUploadFile(true);
  };

  const handleCloseUploadFile = () => {
    getAttachmentInfo();
    setOpenUploadFile(false);
  };
  const handleAccordianChange =
    (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const { themeStretch } = useSettingsContext();
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [openFile, setFileOpen] = useState(false);
  // const handleFileOpen = (loc: any, type: any) => {
  //   setDocuments([
  //     { uri: `${process.env.REACT_APP_HOST_API_KEY}${loc}`.replace(/\\/g, '/'), fileType: type },
  //   ]);
  //   setFileOpen(true);
  // };
  const handleFileClose = () => setFileOpen(false);
  const [openDelete, onDeleteClose] = useState(false);
  const [deleteAttachmentId, setDeleteAttachmentId] = useState<any>();
  const handleDeleteOpen = (attachid: any) => {
    setDeleteAttachmentId(attachid);
    onDeleteClose(true);
  };
  const handledeleteClose = () => {
    setDeleteAttachmentId(null);
    onDeleteClose(false);
  };
  const handleCloseAssessmentConfirmation = () => {
    setAssessmentConfirmation(false);
  };
  const deleteAttachment = () => {
    axios
      .get(`/core/deleteJobAttachments?Id=${deleteAttachmentId}`)
      .then((response) => {
        getAttachmentInfo();
        enqueueSnackbar(response.data);
        onDeleteClose(false);
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: 'error' });
      });
  };
  const markAsConfidential = (attachid: any) => {
    axios
      .get(`/core/markAsConfidential?Id=${attachid}`)
      .then((response) => {
        getAttachmentInfo();
        enqueueSnackbar(response.data);
        onDeleteClose(false);
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: 'error' });
      });
  };

  const markAsPublic = (attachid: any) => {
    axios
      .get(`/core/markAsprivate?Id=${attachid}`)
      .then((response) => {
        getAttachmentInfo();
        enqueueSnackbar(response.data);
        onDeleteClose(false);
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: 'error' });
      });
  };
  function formatFileSize(bytes: any) {
    if (bytes === 0) return '0 Bytes';

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / 1024 ** i).toFixed(2))} ${sizes[i]}`;
  }

  const groupByStage = (d: JobStageData[]) =>
    d.reduce((acc: { [key: string]: { [key: string]: JobStageData[] } }, item) => {
      if (!acc[item.StageName]) {
        acc[item.StageName] = {};
      }
      if (!acc[item.StageName][item.ChecklistName]) {
        acc[item.StageName][item.ChecklistName] = [];
      }
      acc[item.StageName][item.ChecklistName].push(item);
      return acc;
    }, {});

  const handleFileOpen = (loc: any, type: any, filename: string) => {
    const encodedLoc = encodeURIComponent(loc);
    axios
      .get(`/core/getJobFile?loc=${encodedLoc}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const blob = response.data;
        const objectUrl = URL.createObjectURL(blob);
        // const link = document.createElement('a');
        // link.href = objectUrl;
        // link.setAttribute('download', filename);
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        setDocuments([{ uri: objectUrl, fileType: type, fileName: filename }]);
        setFileOpen(true);
      })
      .catch((error) => {
        console.error('Error fetching getJobFile:', error);
      });
  };

  const handleFileDownload = (loc: any, type: any, filename: string) => {
    const encodedLoc = encodeURIComponent(loc);
    axios
      .get(`/core/getJobFile?loc=${encodedLoc}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const blob = response.data;
        const objectUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectUrl;
        link.download = filename;
        link.click();

        window.URL.revokeObjectURL(objectUrl);
      })
      .catch((error) => {
        console.error('Error fetching getJobFile:', error);
      });
  };

  const [issubmitting, setIsSubmitting] = useState(false);
  // const [profilePics, setProfilePics] = useState<any>();

  const postComment = async () => {
    if (!ref.current?.value) return;

    setIsSubmitting(true);

    const payload = {
      Comment: ref.current?.value,
      JobRecordID: data?.JobRecordID,
      Sequence: comments?.length,
    };
    if (ref.current?.value !== '') {
      axios
        .post(`/core/postNewComment?Id=${user?.ID}`, payload)
        .then((_response) => {
          fetchComments();
          if (ref.current) {
            ref.current.value = '';
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: 'error' });
        })
        .finally(() => setIsSubmitting(false));
    } else {
      enqueueSnackbar('Enter Something To Post', { variant: 'warning' });
    }
  };

  const [openRename, setRename] = useState(false);
  const [attachmentName, setName] = useState<any>({});
  const handleRenameOpen = (attcId: number, name: string) => {
    setName({ name, attcId });
    setRename(true);
  };
  const handleRenameClose = () => {
    setRename(false);
  };
  function setAttachmentName(value: any) {
    setName(({ name, attcId }: any) => ({ name: value.target.value, attcId }));
  }
  const rename = () => {
    const encodedLoc = encodeURIComponent(attachmentName.name);
    axios
      .post(`/core/renameAttachment?Id=${attachmentName.attcId}&name=${encodedLoc}`)
      .then((_response) => {
        enqueueSnackbar('Attachment Name Updated Successfully');
        getAttachmentInfo();
        setRename(false);
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: 'error' });
      });
  };

  const [sign, setSign] = useState(false);
  const [emailInput, setEmailInput] = useState('');

  const handleOnChange = (value: SetStateAction<string>) => {
    if (typeof value === 'string') {
      setEmailInput(value);
    } else {
      setEmailInput((prevValue) => {
        const newValue = value(prevValue);
        const temp = true;
        setSign(temp);
        return newValue;
      });
    }
    setSignYes(false);
  };
  // const [emailInput, setEmailInput] = useState(`<!DOCTYPE html>
  // <html lang="en">

  // <body style="font-family: Roboto, Arial, Helvetica, sans-serif; background-color: #ffffff; max-width: 900px;">
  //     <table style="border-collapse: collapse; background-color: #FFFFFF;">
  //         <tbody>
  //             <tr style="background-color: #ffffff;">
  //                 <td style="width: 100%; padding: 20px 20px 0 20px;">
  //                 </td>
  //             </tr>
  //             <tr style="background-color: #ffffff;">
  //                 <td style="width: 100%; padding: 20px;">
  //                     <h3 style="margin: 0; font-weight: 500; color: #111111;">Hi {{.UserName}} </h3>
  //                 </td>
  //             </tr>
  //             <tr style="background-color: #ffffff;">
  //                 <td style="width: 100%; padding: 5px 20px 10px 20px;">
  //                     <p style="margin-top: 0; font-size: 12pt; line-height: 1.5; color: #111111;">
  //                         We have received a request to reset your password on <strong>Energy Advance Australia  Portal.</strong>
  //                         Please click on the link below to reset your password and access the platform.Kindly note the reset link is only valid upto 2 hours.
  //                     </p>
  //                 </td>
  //             </tr>
  //             <tr style="background-color: #ffffff;">
  //                 <td style="width: 100%; padding: 5px 20px 10px 20px;">
  //                     <table style="border-collapse: collapse; border: none;" cellpacing=0 cellpadding=0>
  //                         <tbody>
  //                             <tr>
  //                                 <td style="padding-left: 30px; padding-top: 10px; padding-bottom: 10px;
  //                                 background-color: #00ab55;
  //                                 border-top-left-radius: 4px;
  //                                 border-bottom-left-radius: 4px;">
  //                                 </td>
  //                                 <td
  //                                     style="background-color: #00ab55; padding-bottom: 10px; padding-top: 10px; padding-left: 10px; padding-right: 10px; display: flex; justify-content: center; align-items: center;">
  //                                     <a href="{{.PasswordURL}}" style="color: #ffffff; text-decoration: none; font-size: 11pt;">
  //                                         Reset Password
  //                                     </a>
  //                                 </td>
  //                                 <td style="padding-right: 30px; padding-top: 10px; padding-bottom: 10px;
  //                                 background-color: #00ab55;
  //                                 border-top-right-radius: 4px;
  //                                 border-bottom-right-radius: 4px;">
  //                                 </td>
  //                             </tr>
  //                         </tbody>
  //                     </table>
  //                     <p style="font-size: 12pt; line-height: 1.5; color: #111111;">
  //                         If the button above isn’t working, paste the link below into your browser.
  //                     </p>
  //                     <a href="{{.PasswordURL}}" target="_blank" style="color: #005DB9;">{{.PasswordURL}}</a>
  //                     <p style="font-size: 12pt; line-height: 1.5; color: #111111;">
  //                         If you have already set your password, <a
  //                             href="{{.EAALoginURL}}" style="color: #005DB9;">click here</a> to sign in into the portal.
  //                     </p>
  //                 </td>
  //             </tr>
  //             <tr>
  //                 <td align="center"
  //                     style="padding: 20px 20px; width: 100%;background-color: #414952;bottom: 0;font-family: 'Roboto', Arial, sans-serif; font-size: 10pt; color: #ffffff">
  //                     This email was autogenerated by Energy Advanced Portal. Responses to this mailbox are not monitored. If you
  //                     have any queries, please contact us at
  //                     <a style="color: #ffffff !important;" href="mailto: support@pies.io">support@enegyadvanced.io</a>.
  //                 </td>
  //             </tr>
  //         </tbody>
  //     </table>
  //     </body>

  //     </html>`);
  const [openACPopup, setACPopup] = useState(false);

  const completeAssessment = () => {
    console.log('Rating = ', data?.JobRating);
    setACPopup(true);
    // if (data?.JobRating !== 0) {
    //   if (typeof data?.JobRating === 'number' && data.JobRating > 0.0 && data.JobRating <= 10.0) {
    //     
    //   } else {
    //     enqueueSnackbar('Please enter valid rating', { variant: 'error' });
    //   }
    // } else {
    //   enqueueSnackbar('Please enter valid rating', { variant: 'error' });
    // }
  };
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openSignDialog, setOpenSignDialog] = useState(false);
  const onSave = () => {
    setisLoading(true);
    const formData = new FormData();
    formData.append('email', emailInput);
    formData.append('JobRecordID', data?.JobEmailMsgID.toString() || '');
    formData.append('ID', data?.JobRecordID.toString() || '');
    formData.append(`EntryBy`, user?.ID);

    formData.append('JobNumber', data?.JobNumber || '');

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files1.length; i++) {
      formData.append('files1', files1[i]);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files2.length; i++) {
      formData.append('files2', files2[i]);
    }

    axios
      .post('/core/markAssignmentComplete', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((_response: any) => {
        // console.log(_response);
        fetchComments();
        getAttachmentInfo();
        getJobInfo();
        getJobSlaTime();
        getEmailList();
        // getJobStatusDateByJobId();
        enqueueSnackbar(_response.data);
        setEmailInput('');
        setFiles1([]);
        setFiles2([]);
        setisLoading(false);
        setACPopup(false);
        getJobStatusByProgress();
        NavigateToJobs();
      })
      .catch((_error: any) => {
        // console.log(_error);
        setisLoading(false);
        enqueueSnackbar(_error, { variant: 'error' });
      });
    setOpenConfirmDialog(false);
  };

  // const [signature, setSignature] = useState<Signature | null>(null);
  const [signYes, setSignYes] = useState(true);

  // const addSignature = () => {
  //   console.log('In sign');
  //   axios
  //     .get(`/core/getusersignbyid?Id=${user?.ID}`)
  //     .then((response) => {
  //       if (response.data?.Signature !== undefined) {
  //         setEmailInput((valu: any) => valu + (response.data?.Signature || ''));
  //         console.log('Email input = ', emailInput);
  //         setOpenSignDialog(false);
  //         setSignPresent(true);
  //       }
  //     })
  //     .catch((err) => {
  //       // enqueueSnackbar('No signature found', { variant: 'error' });
  //       console.error('Error fetching status signature:', err);
  //     });
  // };

  const onCancelSign = () => {
    setOpenSignDialog(false);
    setOpenConfirmDialog(false);
    setSignYes(true);
    // setSign(false);
  };

  const onCancel = () => {
    setOpenSignDialog(false);
    setOpenConfirmDialog(false);
    // setSignYes(true);
    // setSign(false);
  };

  const [isLoading, setisLoading] = useState(false);
  const [doWatermark, setDoWatermark] = useState(false);

  const markAssignmentComplete = async () => {
    console.log('Sign value = ', sign);
    console.log('Sign yes = ', signYes);
    if (signYes) {
      if (!sign) {
        setOpenSignDialog(true);
      }
    }
    console.log('Outside');
    if (!signYes) {
      setisLoading(true);
      try {
        if (files1.length === 0) {
          setOpenConfirmDialog(true);
          setisLoading(false);
          return;
        }

        const formData = new FormData();
        formData.append('email', emailInput);
        console.log('Email input = ', emailInput);
        formData.append('JobRecordID', data?.JobEmailMsgID.toString() || '');
        formData.append('ID', data?.JobRecordID.toString() || '');
        formData.append(`EntryBy`, user?.ID);

        formData.append('JobNumber', data?.JobNumber || '');

        formData.append('Watermark', doWatermark.toString());

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < files1.length; i++) {
          const file = files1[i];

          // Check if the item is a File type
          if (file instanceof File) {
            formData.append('files1', file); // Append the file itself

            // Append 'assessment_report' with the file name
            formData.append(`assessment_report`, file.name); // Using file.name to store the file name
          }
        }

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < files2.length; i++) {
          formData.append('files2', files2[i]);
        }
        console.log('Form data = ', formData.getAll('files1'));
        axios
          .post('/core/markAssignmentComplete', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((_response: any) => {
            // console.log('Inside _response');
            fetchComments();
            getAttachmentInfo();
            getJobInfo();
            getJobSlaTime();
            getEmailList();
            // getJobStatusDateByJobId();
            enqueueSnackbar(_response.data);
            setEmailInput('');
            setFiles1([]);
            setFiles2([]);
            setisLoading(false);
            setACPopup(false);
            getJobStatusByProgress();
            setDoWatermark(false);
            NavigateToJobs();
          })
          .catch((_error: any) => {
            // console.log('Inside _error', _error);
            setisLoading(false);
            enqueueSnackbar(_error, { variant: 'error' });
            setDoWatermark(false);
          });
      } catch (error) {
        // console.log(error);
        setDoWatermark(false);
      }
    }
  };

  const sendWithoutSign = async () => {
    console.log('In send without sign');
    setOpenSignDialog(false);
    setSignYes(false);
    setSign(true);
    setisLoading(true);
    try {
      if (files1.length === 0) {
        setOpenConfirmDialog(true);
        setisLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append('email', emailInput);
      formData.append('JobRecordID', data?.JobEmailMsgID.toString() || '');
      formData.append('ID', data?.JobRecordID.toString() || '');
      formData.append(`EntryBy`, user?.ID);

      formData.append('JobNumber', data?.JobNumber || '');

      formData.append('Watermark', doWatermark.toString());

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files1.length; i++) {
        const file = files1[i];

        // Check if the item is a File type
        if (file instanceof File) {
          formData.append('files1', file); // Append the file itself

          // Append 'assessment_report' with the file name
          formData.append(`assessment_report`, file.name); // Using file.name to store the file name
        }
      }

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files2.length; i++) {
        formData.append('files2', files2[i]);
      }
      console.log('Form data = ', formData.getAll('files1'));
      axios
        .post('/core/markAssignmentComplete', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((_response: any) => {
          // console.log('Inside _response');
          fetchComments();
          getAttachmentInfo();
          getJobInfo();
          getJobSlaTime();
          getEmailList();
          // getJobStatusDateByJobId();
          enqueueSnackbar(_response.data);
          setEmailInput('');
          setFiles1([]);
          setFiles2([]);
          setisLoading(false);
          setACPopup(false);
          getJobStatusByProgress();
          setDoWatermark(false);
          NavigateToJobs();
        })
        .catch((_error: any) => {
          // console.log('Inside _error', _error);
          setisLoading(false);
          enqueueSnackbar(_error, { variant: 'error' });
          setDoWatermark(false);
        });
    } catch (error) {
      // console.log(error);
      setDoWatermark(false);
    }
  };

  const cancleACPopup = () => {
    setEmailInput('');
    setFiles1([]);
    setFiles2([]);
    setACPopup(false);
    setSign(false);
    setSignYes(true);
    setisLoading(false);
  };

  const [files1, setFiles1] = useState<(File | string)[]>([]);
  const [files2, setFiles2] = useState<(File | string)[]>([]);

  const handleACMainDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles1([...files1, ...newFiles]);
    },
    [files1]
  );

  const handleACOtherDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles2([...files2, ...newFiles]);
    },
    [files2]
  );

  const handleACOtherRemoveFile = (inputFile: File | string) => {
    const filtered = files2.filter((file) => file !== inputFile);
    setFiles2(filtered);
  };

  const handleACMainFileRemove = (inputFile: File | string) => {
    const filtered = files1.filter((file) => file !== inputFile);
    setFiles1(filtered);
  };

  const [mappedJob, setMappedJob] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    // Make an API request to your Go Fiber backend to fetch job data
    if (id) {
      axios
        .get(`/core/getMappedJob?Id=${id}`) // Replace with your actual API endpoint
        .then((response) => {
          setMappedJob(response.data);
          console.info(response.data);
        })
        .catch((error) => {
          console.error('Error fetching getMappedJob:', error);
        });
    }
  }, [id]);

  const handleListItemClick = (_job: any) => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [openJobComp, setJobComp] = useState(false);

  const handleJobComplete = () => {
    setJobComp(true);
    getJobStatusByProgress();
    getEmailList();
  };

  const handleCloseJobComp = () => {
    getJobInfo();
    getJobSlaTime();
    setJobComp(false);
    getJobStatusByProgress();
  };

  const [exception, setException] = useState<string>('');
  const handelExcptionInputChange = (value: any) => {
    // console.log(value);
  };
  const [emails, setEmails] = useState<EmailData[]>([]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/core/getAllEmailForJob?Id=${id}`)
        .then((response) => {
          setEmails(response.data);
          console.table(response.data);
        })
        .catch((error) => {
          console.error('Error fetching getAllEmailForJob:', error);
        });
    }
  }, [id]);

  // const steps = [
  //   'Select master blaster campaign settings',
  //   'Create an ad group',
  //   'Create an ad',
  //   'Select master blaster campaign settings',
  //   'Create an ad group',
  //   'Create an ad',
  //   'Select master blaster campaign settings',
  //   'Create an ad group',
  //   'Create an ad',
  // ];
  const [openSendMail, setSendMail] = useState(false);
  const sendemail = () => {
    setSendMail(true);
  };

  // const [signature, setSignature] = useState<Signature[]>([]);

  // // useEffect(() => {
  // //   axios
  // //     .get(`/core/getallusersigns`)
  // //     .then((response) => {
  // //       setSignature(response.data as Signature[]);
  // //     , })
  // //     .catch((error) => {
  // //       console.error('Error fetching status date:', error);
  // //     });
  // // });

  const handleCloseSendMail = () => {
    getJobInfo();
    getJobSlaTime();
    setSendMail(false);
    getEmailList();
  };
  const [template, setTemplate] = useState(false);
  // const [last_email, setLastEmail] = useState<any>(null);
  // console.log("Email content info :", emails.length);
  // const length_email = emails.length

  //   if (emails?.length > 0) {
  // //    setLastEmail();
  //     console.log("Email Summary :", emails[emails.length - 1]?.EmailSummary)
  //     const temp_emailsummary = emails[emails.length - 1]?.EmailSummary
  //     setLastEmail('temp_emailsummary')
  //   }

  // const reprocessException = () => {
  //   console.log('Data for reprocess = ', data);
  //   axios
  //     .get(`/core/reprocessException?Id=${id}&UserId=${user?.ID}`)
  //     .then((response) => {
  //       enqueueSnackbar('Exception Reprocesed Successfully');
  //       getJobStatusByProgress();
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching reprocessException:', error);
  //       enqueueSnackbar(error, { variant: 'error' });
  //     });
  // };
  const reprocessException = () => {
    const emptyFields = [];

    if (attachments?.length === 0) {
      console.log('No attachments');
    } else {
      console.log('Attachments');
    }

    if (data?.JobAddrState === '') {
      emptyFields.push('Job State');
    }
    if (data?.JobSiteAddr === '') {
      emptyFields.push('Job Address');
    }
    if (data?.JobType === '') {
      emptyFields.push('Job Type');
    }
    if (data?.JobCode === '') {
      emptyFields.push('Job Code');
    }
    if (data?.JobPrelimFinal === '') {
      emptyFields.push('Job PrelimFinal');
    }
    if (data?.JobDwelling === '') {
      emptyFields.push('Job Dwelling');
    }
  
    // Check for other job codes and enforce attachment check
    if(attachments?.length === 0 && data?.JobCode !== 'CRT'){
      enqueueSnackbar('Must have at least 1 attachment', { variant: 'error' });
    } else if (emptyFields.length === 0) {
      axios
        .get(`/core/reprocessException?Id=${id}&UserId=${user?.ID}`)
        .then((response) => {
          enqueueSnackbar(response?.data?.message);
          getJobStatusByProgress();
          getJobInfo();
          getJobSlaTime();
        })
        .catch((error) => {
          console.error('Error fetching reprocessException:', error);
          enqueueSnackbar(error?.error, { variant: 'error' });
        });
    } else {
      const fieldsString = emptyFields.join(', ');
      enqueueSnackbar(
        `${fieldsString} is missing. Fill details and update before reprocessing the exception.`,
        { variant: 'error' }
      );
    }
  };   

  const [fileType, setFileType] = useState('public');

  const handleCheckboChange = (event: any) => {
    setDoWatermark(event.target.checked);
  };

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);

    const day = `0${date.getDate()}`.slice(-2);
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const year = date.getFullYear();

    const hours = `0${date.getHours()}`.slice(-2);
    const minutes = `0${date.getMinutes()}`.slice(-2);
    const seconds = `0${date.getSeconds()}`.slice(-2);

    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };

  return (
    <Container sx={{ maxWidth: '100% !important', paddingTop: '50px', paddingBottom: '50px' }}>
      <JobCompleteDialog
        open={openJobComp}
        onClose={handleCloseJobComp}
        getComments={fetchComments}
        getEmails={getEmailList}
        job={data}
        attachments={attachments}
        initial_email_content={emails}
      />
      <SendEmailDialog
        open={openSendMail}
        onClose={handleCloseSendMail}
        job={data}
        attachments={attachments}
      />
      <ResponsiveDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        title="Confirm Submit"
        content="Are you sure You want to send email without Assessment Report"
        onSave={onSave}
        onCancel={onCancel}
      />

      <ResponsiveDialog
        open={openSignDialog}
        setOpen={setOpenSignDialog}
        title="Confirm Submit"
        content="Signature is missing. Do you want to Continue ? Yes or No"
        onSave={sendWithoutSign}
        onCancel={onCancelSign}
      />
      <Dialog fullWidth maxWidth="xl" open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Linked Job List</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Job Id</TableCell>
                  <TableCell>Email Subject</TableCell>
                  <TableCell>Job Prelim/Final </TableCell>
                  <TableCell>Assessor Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Job Created At</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mappedJob &&
                  mappedJob.map((job: any) => (
                    <TableRow key={job.JobInfo.JobRecordID}>
                      <TableCell>{job.JobInfo.JobRecordID}</TableCell>
                      <TableCell>{job.JobInfo.JobEmailSubject}</TableCell>
                      <TableCell>{job.JobInfo.JobPrelimFinal}</TableCell>
                      <TableCell>
                        {
                          (assessor?.find((val) => val.ID === job.JobInfo.JobAssessorID) || {})
                            .UserFname
                        }{' '}
                        {
                          (assessor?.find((val) => val.ID === job.JobInfo.JobAssessorID) || {})
                            .UserLname
                        }
                      </TableCell>
                      <TableCell>{job.JobInfo.JobStatus}</TableCell>

                      <TableCell>{job.JobInfo.JobCreatedAt}</TableCell>
                      <TableCell>
                        <Link
                          onClick={() => {
                            navigate(`/viewJobs?id=${job.JobInfo.JobRecordID}&edit=false`);
                            handleCloseDialog();
                          }}
                        >
                          View
                        </Link>
                        /
                        <Link
                          onClick={() => {
                            navigate(`/viewJobs?id=${job.JobInfo.JobRecordID}&edit=true`);
                            handleCloseDialog();
                          }}
                        >
                          Edit
                        </Link>
                      </TableCell>
                      {/* Add more table cells for additional job data */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth="xs" open={openDelete} onClose={handledeleteClose}>
        <DialogTitle sx={{ pb: 2 }}>Confirm Delete </DialogTitle>

        <DialogContent sx={{ typography: 'body2' }}>
          {' '}
          Are you sure want to delete this attachment?{' '}
        </DialogContent>

        <DialogActions sx={{ mt: 1 }}>
          <Button variant="contained" color="error" onClick={deleteAttachment}>
            Delete
          </Button>

          <Button variant="outlined" color="inherit" onClick={handledeleteClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth="xs" open={openRename} onClose={handleRenameClose}>
        <DialogTitle sx={{ pb: 2 }}>Rename </DialogTitle>

        <DialogContent sx={{ typography: 'body2' }}>
          <TextField
            sx={{ mt: 1 }}
            fullWidth
            placeholder="Attachment Name"
            rows={3}
            value={attachmentName.name}
            onChange={(val: any) => setAttachmentName(val)}
          />
        </DialogContent>
        <DialogActions sx={{ mt: 1 }}>
          <Button variant="contained" color="secondary" onClick={rename}>
            Update
          </Button>

          <Button variant="outlined" color="inherit" onClick={handleRenameClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth="lg" open={openACPopup} onClose={cancleACPopup}>
        <DialogTitle sx={{ pb: 2 }}>Assessment Report </DialogTitle>

        <DialogContent sx={{ typography: 'body2' }}>
          <Stack spacing={2}>
            <Typography id="Notes" sx={{ fontSize: '17px', marginTop: 1 }}>
              Email Content
            </Typography>
            {/* <TextField
              sx={{ mt: 1 }}
              fullWidth
              name="Email Content"
              placeholder="Write Email Content..."
              multiline
              rows={4}
              value={emailInput}
              onChange={(val: any) => {
                console.log(val.target.value, emailInput);
                setEmailInput(val.target.value);
              }}
            /> */}
            <SelectTemplate
              open={template}
              onClose={() => {
                setTemplate(false);
              }}
              setEditor={(val: any) => {
                setEmailInput(val);
              }}
              job_data={data}
            />
            <Button
              variant="text"
              onClick={() => {
                setTemplate(true);
              }}
            >
              {' '}
              Select Template
            </Button>

            <Editor
              value={emailInput}
              onChange={(val: any) => {
                setEmailInput(val);
              }}
              onchan={handleOnChange}
            />
            <Typography id="Notes" sx={{ fontSize: '17px', marginTop: 1 }}>
              Assessment Report Document ( Apply Watermark{' '}
              <Checkbox value={doWatermark} onChange={handleCheckboChange} />)
              {doWatermark && (
                <Typography id="Notes" sx={{ fontSize: '15px', marginTop: 1 }}>
                  Note : Watermark will only be applied if Job Code is Verification (Single/Double
                  Storey) or Job is Prelim.
                </Typography>
              )}
            </Typography>
            <Upload
              multiple
              accept={{ 'application/pdf': [] }}
              files={files1}
              onDrop={handleACMainDrop}
              onRemove={handleACMainFileRemove}
            />
            <Typography id="Notes" sx={{ fontSize: '17px', marginTop: 1 }}>
              Other Documents (Optional)
            </Typography>
            <Upload
              multiple
              files={files2}
              onDrop={handleACOtherDrop}
              onRemove={handleACOtherRemoveFile}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ mt: 1 }}>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="secondary"
            onClick={markAssignmentComplete}
          >
            Save
          </LoadingButton>

          <Button variant="outlined" color="inherit" onClick={cancleACPopup} disabled={isLoading}>
            Back
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={openFile}
        onClose={handleFileClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Scrollbar>
          openJobComp
          <Box
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}
          >
            <Box
              sx={{
                height: '100%',
                width: '900px',
              }}
            >
              {' '}
              <IconButton
                onClick={handleFileClose}
                sx={{
                  position: 'fixed',
                  top: '10px',
                  right: '10px',
                  color: 'aliceblue',
                }}
              >
                <CloseIcon />
              </IconButton>
              <DocViewer
                documents={documents}
                ref={docViewerRef}
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: false,
                    retainURLParams: false,
                  },
                  csvDelimiter: ',',
                  pdfZoom: {
                    defaultZoom: 1.1,
                    zoomJump: 0.2,
                  },

                  pdfVerticalScrollByDefault: false,
                }}
                pluginRenderers={DocViewerRenderers}
              />
            </Box>
          </Box>
        </Scrollbar>
      </Modal>
      <Helmet>
        <title> Jobs | Energy Advance</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        {edit && (
          <CustomBreadcrumbs
            heading="View Job"
            links={[
              {
                name: 'Jobs',
                href: '/jobs',
              },
              { name: 'View Job' },
            ]}
          />
        )}
        {!edit && (
          <CustomBreadcrumbs
            heading="Edit Job"
            links={[
              {
                name: 'Jobs',
                href: '/jobs',
              },
              { name: 'Edit Job' },
            ]}
          />
        )}
        <Dialog fullWidth maxWidth="lg" open={open} onClose={handleExceptionClose}>
          {data && <ExceptionView data={data} handleClose={handleExceptionClose} />}
        </Dialog>

        {data && (
          <>
            <Typography typography="h6" color="#788490">
              Job Id: {data.JobRecordID} &nbsp;&nbsp;&nbsp;
            </Typography>
            {splitJobFlag && (
              <Typography typography="h6" color="yellowgreen" sx={{ marginTop: '20px' }}>
                This Job is a Split Job of Original Job
              </Typography>
            )}
            {/* {recheckJobFlag && (
              <Typography typography="h6" color="yellowgreen" sx={{ marginTop: '20px' }}>
                This Job is a Reassessment Job of Original Job
              </Typography>
            )} */}
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                spacing={2}
                display="flow"
                xs={12}
                md={12}
                sx={{ marginTop: 2, marginBottom: 2 }}
              >
                <Box
                  rowGap={3}
                  columnGap={5}
                  display="grid"
                  gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                  }}
                >
                  {/* <RHFTextField
                    name="ReqName"
                    label="Job Requestor"
                    sx={{ width: '100%' }}
                    disabled={edit}
                  /> */}

                  <RHFSelect
                    name="JobRequestorID"
                    label="Job Requestor*"
                    placeholder="Requestor"
                    disabled={edit}
                  >
                    <MenuItem value="">None</MenuItem>
                    {requestors &&
                      requestors.map((requestor) => (
                        <MenuItem key={requestor.ID} value={requestor.ID}>
                          {requestor.ReqName} : {requestor.ReqSubloc}
                        </MenuItem>
                      ))}
                  </RHFSelect>
                  {/* <RHFTextField
                    name="ReqSubloc"
                    label="Requestor Sub Location"
                    disabled
                    value={requestorSubLocation}
                    sx={{ width: '100%' }}
                  /> */}
                  {/* {user?.UserRole !== 'Assessor' && (
                    <RHFTextField
                      name="JobNumber"
                      label="Job Number*"
                      disabled={edit}
                      sx={{ width: '100%' }}
                    />
                  )} */}
                  <RHFTextField
                    name="JobNumber"
                    label="Job Number*"
                    disabled={edit}
                    sx={{ width: '100%' }}
                  />
                  {/* {user?.UserRole === 'Assessor' && (
                    <RHFTextField
                      name="JobNumber"
                      label="Job Number*"
                      disabled
                      sx={{ width: '100%' }}
                    />
                  )} */}
                  {/* <RHFCheckbox name="JobPostAssessmentFlag" label="Post Assessment" disabled /> */}
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        disabled
                        defaultChecked={JobPostAssessmentFlag}
                        name="JobPostAssessmentFlag"
                      />
                    }
                    label="Post Assessment"
                  /> */}
                  {/* <RHFCheckbox name="JobIsUrgent" label="Urgent" disabled={edit} /> */}

                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={user?.UserRole === 'Assessor' || edit}
                        defaultChecked={JobIsUrgent}
                        name="JobIsUrgent"
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Urgent"
                  />

                  <RHFRadioGroup
                    row
                    name="JobType"
                    label="Job Type*"
                    spacing={4}
                    // disabled={edit}
                    disabled={user?.UserRole === 'Assessor' || edit}
                    options={[
                      { value: 'new', label: 'New' },
                      { value: 'existing', label: 'Existing' },
                    ]}
                  />

                  <RHFSelect
                    sx={{ width: '100%' }}
                    disabled={edit}
                    name="Ncc_Type"
                    label="NCC Type*"
                    variant="outlined"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="NCC 2019">NCC 2019</MenuItem>
                    <MenuItem value="NCC 2022">NCC 2022</MenuItem>
                  </RHFSelect>
                  <FormLabel>
                    Reprocess Details:{' '}
                    {data.JobReprocessFlag === true ? (
                      <Link href="#" onClick={handleListItemClick}>
                        <Iconify icon="carbon:view-filled" />
                        View Job
                      </Link>
                    ) : (
                      <Typography>No linked jobs</Typography>
                    )}
                  </FormLabel>
                  <RHFSelect
                    disabled={user?.UserRole === 'Assessor' || edit}
                    name="JobCode"
                    label="Job Code*"
                    placeholder="Job Code"
                  >
                    {/* <MenuItem value="">None</MenuItem> */}
                    {jobCodes &&
                      jobCodes.map((code) => (
                        <MenuItem key={code.JobCode} value={code.JobCode}>
                          {code.JobCode}
                        </MenuItem>
                      ))}
                  </RHFSelect>

                  <RHFSelect
                    // disabled={user?.UserRole === 'Assessor' || edit}
                    sx={{ width: '100%' }}
                    name="JobDwelling"
                    label="Dwelling Type*"
                    variant="outlined"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="single storey">Single Storey</MenuItem>
                    <MenuItem value="double storey">Double Storey</MenuItem>
                    <MenuItem value="multi-level dwelling">Multi-Level Dwelling</MenuItem>
                    <MenuItem value="single storey dual occ">Single Storey Dual Occ</MenuItem>
                    <MenuItem value="double-storey dual occ">Double-Storey Dual Occ</MenuItem>
                    <MenuItem value="single storey + granny/studio">
                      Single Storey + Granny/Studio
                    </MenuItem>
                    <MenuItem value="double storey + granny/studio">
                      Double Storey + Granny/Studio
                    </MenuItem>
                  </RHFSelect>
                  <RHFSelect
                    disabled={user?.UserRole === 'Assessor' || edit}
                    sx={{ width: '100%' }}
                    name="JobPrelimFinal"
                    label="Job Prelim/Final*"
                    variant="outlined"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="prelim">Prelim</MenuItem>
                    <MenuItem value="final">Final</MenuItem>
                  </RHFSelect>

                  {user?.UserRole === 'Assessor' && (
                    <RHFSelect
                      disabled={
                        data?.JobStatus === 'exception' ||
                        edit ||
                        assessorNotAllowedStatuses.includes(data.JobStatus)
                      }
                      sx={{ width: '100%' }}
                      name="JobStatus"
                      label="Job Status"
                      variant="outlined"
                    >
                      <MenuItem value="assigned">Assigned</MenuItem>
                      {data?.JobStatus !== 'assessment started' ||
                        (otherStatus.includes(data.JobStatus) && (
                          <MenuItem value="assessment started">Assessment Started</MenuItem>
                        ))}
                      {data?.JobStatus !== 'workflow to action' ||
                        (otherStatus.includes(data.JobStatus) && (
                          <MenuItem value="workflow to action">Workflow to Action</MenuItem>
                        ))}
                      {/* <MenuItem value="assessment completed">Assessment Completed</MenuItem> */}
                      <MenuItem value="cancelled">Cancelled</MenuItem>
                      <MenuItem value="on hold">On Hold</MenuItem>
                      {data?.JobStatus === 'marked as complete' && (
                        <MenuItem value="marked as complete">Marked as Complete</MenuItem>
                      )}
                      {data?.JobStatus === 'exception' && (
                        <MenuItem value="exception">Exception</MenuItem>
                      )}
                      {data?.JobStatus === 'pending validation' && (
                        <MenuItem value="pending validation">Pending Validation</MenuItem>
                      )}
                    </RHFSelect>
                  )}
                  {user?.UserRole !== 'Assessor' && data?.JobStatus !== 'exception' && (
                    <RHFSelect
                      disabled={edit}
                      sx={{ width: '100%' }}
                      name="JobStatus"
                      label="Job Status"
                      variant="outlined"
                    >
                      <MenuItem value="new">New Job</MenuItem>
                      <MenuItem value="assigned">Assigned</MenuItem>
                      <MenuItem value="cancelled">Cancelled</MenuItem>
                      <MenuItem value="on hold">On Hold</MenuItem>
                      <MenuItem value="pending validation">Pending Validation</MenuItem>
                      <MenuItem value="exception">Exception</MenuItem>
                      {data?.JobStatus === 'marked as complete' && (
                        <MenuItem value="marked as complete">Marked as Complete</MenuItem>
                      )}
                      {data?.JobStatus === 'assessment started' && (
                        <MenuItem value="assessment started">Assessment Started</MenuItem>
                      )}
                      {data?.JobStatus === 'workflow to action' && (
                        <MenuItem value="workflow to action">Workflow To Action</MenuItem>
                      )}
                    </RHFSelect>
                  )}

                  {user?.UserRole !== 'Assessor' && data?.JobStatus === 'exception' && (
                    <RHFSelect
                      disabled={edit}
                      sx={{ width: '100%' }}
                      name="JobStatus"
                      label="Job Status"
                      variant="outlined"
                    >
                      <MenuItem value="exception">Exception</MenuItem>
                      <MenuItem value="cancelled">cancelled</MenuItem>
                      <MenuItem value="on hold">On Hold</MenuItem>
                    </RHFSelect>
                  )}

                  <RHFSelect
                    disabled={user?.UserRole === 'Assessor' || edit}
                    sx={{ width: '100%' }}
                    name="JobStage"
                    label="Job Stage*"
                    variant="outlined"
                    // value={selectedStage}
                    onChange={(e) => handleStageChange(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Stage 1">Stage 1</MenuItem>
                    <MenuItem value="Stage 2">Stage 2</MenuItem>
                    {/* <MenuItem value="Stage 3">Stage 3</MenuItem> */}
                    <MenuItem value="Audit Stage 1">Audit Stage 1</MenuItem>
                    <MenuItem value="Audit Stage 2">Audit Stage 2</MenuItem>
                    {/* <MenuItem value="Audit Stage 3">Audit Stage 3</MenuItem> */}
                    <MenuItem value="RPT">RPT</MenuItem>
                    <MenuItem value="BX + WOH"> BX + WOH</MenuItem>
                    <MenuItem value="workflow"> Workflow</MenuItem>
                  </RHFSelect>

                  {/* <RHFTextField
                    name="JobFloors"
                    label="Job Floors"
                    sx={{ width: '100%' }}
                    type="number"
                    disabled={edit}
                  /> */}

                  <RHFTextField
                    name="JobEmailSubject"
                    label="Job Email Subject*"
                    sx={{ width: '100%' }}
                    disabled={user?.UserRole === 'Assessor' || edit}
                  />

                  <TextField
                    label="State"
                    value={inputValue}
                    onChange={handleInputChange}
                    fullWidth
                    disabled={edit}
                  />

                  <RHFTextField
                    name="JobSiteAddr"
                    multiline
                    rows={3}
                    label="Job Address*"
                    sx={{ width: '100%', height: '' }}
                    disabled={edit}
                  />
                  {suggestions.map((suggestion, index) => (
                    <MenuItem
                      key={index}
                      value={suggestion}
                      onClick={() => handleSuggestionSelect(suggestion)}
                      sx={{ background: 'grey', borderRadius: '25px' }}
                    >
                      {suggestion}
                    </MenuItem>
                  ))}
                  {user?.UserRole !== 'Assessor' && (
                    <RHFSelect
                      disabled={edit}
                      name="JobAssessorID"
                      label="Job Assessor"
                      placeholder="Assessor"
                    >
                      <MenuItem value="0">None</MenuItem>
                      {assessor &&
                        assessor.map((assesso) => (
                          <MenuItem key={assesso.ID} value={assesso.ID}>
                            {assesso.UserFname} {assesso.UserLname}
                          </MenuItem>
                        ))}
                    </RHFSelect>
                  )}

                  {/* <TextField disabled={edit} name="fullName" label="Job Assessor" sx={{width:'100%'}} value={data.UserFname}/> */}

                  <RHFTextField
                    name="JobRating"
                    label="Job Rating"
                    sx={{ width: '100%' }}
                    disabled={edit}
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      inputFormat="DD-MM-YYYY HH:mm:ss"
                      disabled
                      renderInput={(props) => <TextField {...props} sx={{ width: '100%' }} />}
                      label="Job Created At"
                      value={data.JobCreatedAt}
                      onChange={setCreatedDate}
                    />
                  </LocalizationProvider>
                  {jobSlaTime && (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          inputFormat="DD-MM-YYYY HH:mm:ss"
                          disabled={userrole === 'Assessor' || edit}
                          renderInput={(props) => <TextField {...props} sx={{ width: '100%' }} />}
                          label="Job SLA Time"
                          value={jobSlaTime ? new Date(jobSlaTime.JobSlaTime) : null}
                          onChange={(newDate) => {
                            setJobSlaTime({ ...jobSlaTime, JobSlaTime: newDate?.toString() });
                          }}
                        />
                      </LocalizationProvider>

                      <RHFSelect
                        name="AWOH_VALID"
                        label="WOH Status"
                        sx={{ width: '100%' }}
                        disabled={user?.UserRole === 'Assessor' || edit}
                      >
                        <MenuItem value="">None</MenuItem>
                        <MenuItem value="WOH">WOH</MenuItem>
                      </RHFSelect>

                      <RHFSelect
                        label="Additional Job Code"
                        name="AdditionalJobCode"
                        sx={{ width: '100%' }}
                        disabled={user?.UserRole === 'Assessor' || edit}
                      >
                        <MenuItem value="">None</MenuItem>
                        <MenuItem value="OTH5">OTH5</MenuItem>
                        <MenuItem value="OTH10">OTH10</MenuItem>
                        <MenuItem value="OTH20">OTH20</MenuItem>
                        <MenuItem value="RA">RA</MenuItem>
                        <MenuItem value="RAQD">RAQD</MenuItem>
                        <MenuItem value="VRA">VRA</MenuItem>
                        <MenuItem value="BXMAT">BXMAT</MenuItem>
                        <MenuItem value="BXSUB">BXSUB</MenuItem>
                        <MenuItem value="ADIY">ADIY</MenuItem>
                        <MenuItem value="CRT">CRT</MenuItem>
                        <MenuItem value="ADSPA">ADSPA</MenuItem>
                        <MenuItem value="ASSPA">ASSPA</MenuItem>
                        <MenuItem value="AIPA">AIPA</MenuItem>
                        <MenuItem value="ARCO">ARCO</MenuItem>
                      </RHFSelect>

                      <RHFTextField
                        label="SLA Message"
                        // value={jobSlaTime.JobSlaMessage}
                        sx={{ width: '100%' }}
                        disabled={user?.UserRole === 'Assessor' || edit}
                        name="JobSlaMessage"
                      />
                    </>
                  )}

                  {/* {jobStatusDate &&
                    jobStatusDate.map((status, index) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          disabled={edit}
                          renderInput={(props) => <TextField {...props} sx={{ width: '100%' }} />}
                          label={
                            status.JobStatus.charAt(0).toUpperCase() + status.JobStatus.slice(1)
                          }
                          value={status.JobRecordTimestamp}
                          onChange={setCreatedDate}
                        />
                      </LocalizationProvider>
                    ))} */}
                </Box>

                {data.JobStatus === 'exception' && (
                  <>
                    <Typography
                      id="Notes"
                      sx={{ fontSize: '18px', marginTop: 3, marginBottom: 0.5 }}
                    >
                      Exception
                    </Typography>
                    <TextField
                      name="exception"
                      fullWidth
                      disabled={false}
                      value={exception}
                      onChange={handelExcptionInputChange}
                      multiline
                      rows={3}
                    />
                  </>
                )}
                {/* <Box sx={{ width: '100%', marginTop: '50px' }}>
                  <Stepper activeStep={jobStatusProgress.length - 1} alternativeLabel>
                    {jobStatusProgress.map((jobStatus: any) => (
                      <Step key={jobStatus.JobStatus}>
                        <StepLabel>
                          {jobStatus.JobStatus.charAt(0).toUpperCase() +
                            jobStatus.JobStatus.slice(1)}
                          <br />
                          {jobStatus.JobRecordTimestamp}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box> */}

                {/* <Box sx={{ width: '100%', marginTop: '50px', overflowX: 'auto', maxWidth: '100%' }}>
                  <Stepper activeStep={jobStatusProgress.length - 1} alternativeLabel>
                    {jobStatusProgress.map((jobStatus: any) => {
                      const assigned_assessor = assessor?.find(
                        (temp_assessor) => String(temp_assessor.ID) === jobStatus.JobNumber
                      );

                      return (
                        <Step key={jobStatus.JobStatus}>
                          <StepLabel>
                            {jobStatus.JobStatus.charAt(0).toUpperCase() +
                              jobStatus.JobStatus.slice(1)}
                            <br />
                            {jobStatus.JobRecordTimestamp}
                            {jobStatus.JobStatus === 'assigned' && assessor && (
                              <>
                                <br />({assigned_assessor?.UserFname} {assigned_assessor?.UserLname}
                                )
                              </>
                            )}
                          </StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Box> */}
                <Grid
                  item
                  spacing={1}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    marginTop: '10px',
                    width: '105%',
                  }}
                >
                  {jobStage !== 'Stage 1' &&
                    jobStage !== '' &&
                    (data.JobStatus === 'assigned' || data.JobStatus === 'assessment started') &&
                    !edit && (
                      // <Button
                      //   variant="contained"
                      //   color="primary"
                      //   onClick={prevstage}
                      //   sx={{ marginRight: 1, borderRadius: '20px' }}
                      // >
                      //   Move To Previous Stage
                      // </Button>
                      <Tooltip title="Move to the previous stage of the job" placement="top">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={prevstage}
                          sx={{ marginRight: 1, borderRadius: '20px' }}
                        >
                          Move To Previous Stage
                        </Button>
                      </Tooltip>
                    )}
                  {(jobStage.includes('Stage') ||
                    (jobStage === 'RPT' && awoh !== '' && ncc_type === 'NCC 2022') ||
                    (jobStage === 'RPT' && data.JobAddrState.includes('NSW'))) &&
                    data.JobStatus === 'assessment started' &&
                    nextStageVal === 'success' &&
                    !edit && (
                      <Tooltip title="Move to the Next stage of the job" placement="top">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={nextStage}
                          sx={{ marginRight: 1, borderRadius: '20px' }}
                        >
                          Move To Next Stage
                        </Button>
                      </Tooltip>
                    )}
                  {jobStage !== 'workflow' &&
                    jobStage !== 'RPT' &&
                    jobStage !== 'BX + WOH' &&
                    jobStage !== '' &&
                    (data.JobStatus === 'assessment started' ||
                      data.JobStatus === 'stage completed' ||
                      data.JobStatus === 'workflow to action') &&
                    !edit && (
                      <Tooltip
                        title="This button is for current user to fill Checklist"
                        placement="top"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ marginRight: '15px', borderRadius: '20px' }}
                          onClick={handleFillChecklistClick}
                          // disabled={isButtonDisabled}
                          disabled={!selectedStage}
                        >
                          {editChk ? 'Edit Checklist' : 'Fill Checklist'}
                        </Button>
                      </Tooltip>
                    )}
                  <Stack>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-title"
                      aria-describedby="modal-description"
                    >
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          width: 1550,
                          height: '100%',
                          bgcolor: 'background.paper',
                          boxShadow: 24,
                          p: 4,
                          borderRadius: '10px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          overflowY: 'auto',
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{ marginLeft: '35px', fontWeight: 'bold', fontSize: 'large' }}
                        >
                          Job Record ID : {jobRecordID} | Stage : {stageName} | Checklist : {cName}
                        </Typography>
                        <Container sx={{ maxWidth: '100% !important', marginTop: '40px' }}>
                          <TableContainer component={Paper} sx={{ width: '100%' }}>
                            <Table aria-label="stage information table">
                              <TableHead>
                                <TableRow>
                                  <TableCell style={{ fontWeight: 'bold', fontSize: 'large' }}>
                                    ID
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    colSpan={3}
                                    style={{ width: '40%', fontWeight: 'bold', fontSize: 'large' }}
                                  >
                                    Question
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      color: 'green',
                                      fontWeight: 'bold',
                                      fontSize: 'large',
                                    }}
                                  >
                                    YES
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      color: 'red',
                                      fontWeight: 'bold',
                                      fontSize: 'large',
                                    }}
                                  >
                                    NO
                                  </TableCell>
                                  <TableCell style={{ fontWeight: 'bold', fontSize: 'large' }}>
                                    N/A
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ fontWeight: 'bold', fontSize: 'large' }}
                                  >
                                    Notes
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {/* {headers.map((header, headerIndex) => (
                                  <React.Fragment key={headerIndex}>
                                    <TableRow>
                                      <TableCell
                                        colSpan={4}
                                        style={{ fontWeight: 'bold', fontSize: 'larger' }}
                                      >
                                        {header}
                                      </TableCell>
                                    </TableRow> */}
                                {renderRows(rowStage)}
                                {/* <TableRow>
                                  <TableCell colSpan={4}>
                                    <Divider />
                                  </TableCell>
                                </TableRow> */}
                                {/* </React.Fragment> */}
                                {/* ))} */}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Container>
                        <Grid
                          container
                          spacing={1}
                          sx={{ justifyContent: 'flex-end', marginTop: 2 }}
                        >
                          <Button
                            variant="contained"
                            // color="primary"
                            onClick={saveChanges}
                            sx={{
                              marginRight: 2,
                              backgroundColor:
                                user?.UserRole !== 'Assessor' ? '#9575CD' : 'primary',
                            }}
                            // disabled={isSaveButtonDisabled}
                          >
                            Save and Update
                          </Button>
                          <Button variant="contained" color="secondary" onClick={handleClose}>
                            Cancel
                          </Button>
                        </Grid>
                      </Box>
                    </Modal>
                  </Stack>
                  {!edit && (user?.UserRole === 'Management' || (user?.UserRole === 'Workflow' && user?.ID === 3)) && (
                    <Tooltip title="This button send email to client." placement="top">
                      <Button
                        onClick={sendemail}
                        sx={{
                          marginRight: 1,
                          backgroundColor: user?.UserRole !== 'Assessor' ? '#9575CD' : 'primary',
                        }}
                        variant="contained"
                      >
                        Send Email to Client
                      </Button>
                    </Tooltip>
                  )}

                  {!edit &&
                    user?.UserRole === 'Assessor' &&
                    data.JobStatus === 'assessment started' &&
                    (data.JobStage === 'RPT' || data.JobStage === 'BX + WOH') && (
                      <Tooltip
                        title="If you want to send email to requestor click on the button"
                        placement="top"
                      >
                        <Button
                          onClick={sendemail}
                          sx={{ marginRight: 1 }}
                          variant="contained"
                          color="primary"
                        >
                          Send Email to Workflow
                        </Button>
                      </Tooltip>
                    )}

                  {data.JobStatus === 'exception' && user?.UserRole !== 'Assessor' && (
                    <Tooltip
                      title="This button reprocesses jobs which is in exception status"
                      placement="top"
                    >
                      <Button
                        variant="soft"
                        color="primary"
                        onClick={reprocessException}
                        sx={{ marginRight: 1 }}
                      >
                        Reprocess Exception
                      </Button>
                    </Tooltip>
                  )}
                  {!edit && (
                    <>
                      {(user?.UserRole === 'Assessor' || user?.UserRole === 'Management' || user?.UserRole === 'Workflow') && (
                        <>
                          {data.JobStatus !== 'assessment started' &&
                            data.JobStatus !== 'exception' &&
                            data.JobStatus !== 'cancelled' &&
                            data.JobStatus !== 'new' &&
                            data.JobStatus !== 'workflow to action' &&
                            data.JobStatus !== 'marked as complete' &&
                            data.JobStatus !== 'pending validation' &&
                            data.JobStage !== 'workflow' &&
                            data.JobStage !== '' &&
                             (
                              <Tooltip
                                title="This button starts the assessment for job"
                                placement="top"
                              >
                                <Button
                                  onClick={openAssessmentConfirmation}
                                  sx={{ marginRight: 1 }}
                                  variant="contained"
                                  color="primary"
                                >
                                  Start Assessment
                                </Button>
                              </Tooltip>
                            )}

                          {/* {user?.UserRole === 'Management' && (
                            <>
                              {data.JobStatus !== 'assessment started' &&
                                data.JobStatus !== 'exception' &&
                                data.JobStatus !== 'cancelled' &&
                                data.JobStatus !== 'new' &&
                                data.JobStatus !== 'workflow to action' && (
                                  <Tooltip
                                    title="This button start the assessment for job"
                                    placement="top"
                                  >
                                    <Button
                                      onClick={openAssessmentConfirmation}
                                      sx={{ marginRight: 1 }}
                                      variant="contained"
                                      color="primary"
                                    >
                                      Start Assessment
                                    </Button>
                                  </Tooltip>
                                )}
                              {data.JobStatus !== 'workflow to action' &&
                                data.JobStatus !== 'marked as complete' &&
                                data.JobStatus !== 'pending validation' &&
                                data.JobStatus !== 'assigned' &&
                                (jobStage === 'RPT' || jobStage === 'BX + WOH') && (
                                  // data.JobStatus !== 'reassess initiated' && (
                                  <Tooltip
                                    title="This button complete the job and send to workflow to take action."
                                    placement="top"
                                  >
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      sx={{ marginRight: 1 }}
                                      onClick={completeAssessment}
                                    >
                                      Send to Workflow
                                    </Button>
                                  </Tooltip>
                                )}
                            </>
                          )} */}

                          <Dialog
                            fullWidth
                            maxWidth="xs"
                            open={assessmentConfirmation}
                            onClose={handleCloseAssessmentConfirmation}
                          >
                            <DialogTitle sx={{ pb: 2 }}>Start Assessment</DialogTitle>

                            <DialogContent sx={{ typography: 'body2' }}>
                              {' '}
                              Are you sure want to Start the Assessment?{' '}
                            </DialogContent>

                            <DialogActions sx={{ mt: 1 }}>
                              <Button variant="contained" color="success" onClick={startAssessment}>
                                Yes
                              </Button>

                              <Button
                                variant="outlined"
                                color="inherit"
                                onClick={handleCloseAssessmentConfirmation}
                              >
                                No
                              </Button>
                            </DialogActions>
                          </Dialog>

                          {/* {data.JobStatus === 'workflow to action' && (
                            <Button
                              variant="soft"
                              color="primary"
                              onClick={recheckJobInitiated}
                              sx={{ marginRight: 1 }}
                            >
                              Reassess Initiated
                            </Button>
                          )}
                          {data.JobStatus === 'reassess initiated' &&
                            initiatingAssessorID !== user?.UserID && (
                              <Button
                                variant="soft"
                                color="primary"
                                onClick={recheckJobStarted}
                                sx={{ marginRight: 1 }}
                              >
                                Reassess Started
                              </Button>
                            )}
                          {data.JobStatus === 'reassess started' && (
                            <Button
                              variant="soft"
                              color="primary"
                              onClick={recheckjobcompleted}
                              sx={{ marginRight: 1 }}
                            >
                              Reassess Completed
                            </Button>
                          )} */}

                          {/* {data.JobStatus !== 'reassess completed' && */}
                          {data.JobStatus !== 'workflow to action' &&
                            data.JobStatus !== 'marked as complete' &&
                            data.JobStatus !== 'pending validation' &&
                            data.JobStatus !== 'assigned' &&
                            (jobStage === 'RPT' || jobStage === 'BX + WOH') && (
                              // data.JobStatus !== 'reassess initiated' &&
                              // data.JobStatus !== 'reassess started' && (
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{ marginRight: 1 }}
                                onClick={completeAssessment}
                              >
                                Send Job to Workflow
                              </Button>
                            )}
                        </>
                      )}

                      {((user?.UserRole === 'Workflow' && user?.ID === 3) || 
                        user?.UserRole === 'Management') &&
                        data.JobStatus !== 'marked as complete' &&
                        (data.JobStage === 'RPT' ||
                          data.JobStage === 'workflow' ||
                          data.JobStage === 'BX + WOH') && (
                          <Tooltip title="This button send job to the client" placement="top">
                            <Button
                              variant="contained"
                              // color="primary"
                              sx={{
                                marginRight: 1,
                                backgroundColor:
                                  user?.UserRole !== 'Assessor' ? '#9575CD' : 'primary.main',
                              }}
                              onClick={handleJobComplete}
                            >
                              Send job back to Client
                            </Button>
                          </Tooltip>
                        )}
                    </>
                  )}
                  {user?.UserRole !== 'Assessor' && !edit && (
                    <Tooltip
                      title="This button creates duplicate copy of the original job"
                      placement="top"
                    >
                      <Button
                        variant="contained"
                        // color="primary"
                        onClick={splitJob}
                        sx={{
                          marginRight: 1,
                          backgroundColor:
                            user?.UserRole !== 'Assessor' ? '#9575CD' : 'primary.main',
                        }}
                      >
                        Split Job
                      </Button>
                    </Tooltip>
                  )}
                  {!edit &&
                    user?.UserRole === 'Assessor' &&
                    data.JobStatus !== 'new' &&
                    data.JobStatus !== 'marked as complete' &&
                    data.JobStatus !== 'pending validation' &&
                    data.JobStatus !== 'cancelled' &&
                    data.JobStatus !== 'exception' && (
                      <Tooltip title="This button update the job information" placement="top">
                        <Button
                          variant="contained"
                          // color="primary"9575CD
                          type="submit"
                          sx={{
                            marginRight: 1,
                            backgroundColor: user?.UserRole !== 'Assessor' ? '#9575CD' : 'primary',
                          }}
                        >
                          Save
                        </Button>
                      </Tooltip>
                    )}
                  {!edit && user?.UserRole !== 'Assessor' && (
                    <Tooltip title="This button update the job information" placement="top">
                      <Button
                        variant="contained"
                        // color="primary"
                        type="submit"
                        sx={{
                          marginRight: 1,
                          backgroundColor: user?.UserRole !== 'Assessor' ? '#9575CD' : 'primary',
                        }}
                      >
                        Save
                      </Button>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
              <Grid item lg={12}>
                <Typography
                  id="Notes"
                  typography="h6"
                  color="yellowgreen"
                  sx={{
                    marginTop: 2,
                    width: '180px',
                    borderRadius: '5px',
                    marginLeft: '-40px',
                  }}
                >
                  Job Status Progress
                </Typography>
              </Grid>
              <Box sx={{ width: '100%', marginTop: '20px' }}>
                <TableContainer
                  component={Paper}
                  sx={{ maxHeight: 400, overflowY: 'auto' }}
                  ref={tableBodyRef}
                >
                  <Table stickyHeader aria-label="job status progress table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Sr No</TableCell>
                        <TableCell>Job Stage</TableCell>
                        <TableCell>Assessor</TableCell>
                        <TableCell>Job Status</TableCell>
                        <TableCell>Timestamp</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {jobStatusProgress?.map((jobStatus: any, index: any) => {
                        const assignedAssessor = allAssessor?.find(
                          (tempAssessor) => String(tempAssessor.ID) === jobStatus.JobNumber
                        );

                        return (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell> {/* Add Sr No */}
                            <TableCell>{jobStatus.JobStage}</TableCell>
                            <TableCell>
                              {allAssessor ? (
                                <>
                                  {assignedAssessor?.UserFname} {assignedAssessor?.UserLname}
                                </>
                              ) : (
                                'N/A'
                              )}
                            </TableCell>
                            <TableCell>
                              {jobStatus.JobStatus.charAt(0).toUpperCase() +
                                jobStatus.JobStatus.slice(1)}
                            </TableCell>
                            <TableCell>{jobStatus.JobRecordTimestamp}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </FormProvider>
            <Grid item lg={12}>
              <Typography
                id="Notes"
                typography="h6"
                color="yellowgreen"
                sx={{
                  marginTop: 2,
                  width: '180px',
                  borderRadius: '5px',
                  marginLeft: '-40px',
                }}
              >
                All Emails List
              </Typography>

              <Grid item lg={12}>
                <Accordion
                  expanded={expanded === 'panel4'}
                  onChange={handleAccordianChange('panel4')}
                >
                  <AccordionSummary
                    expandIcon={<Iconify icon="" />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography sx={{ flex: '1', width: '33%', flexShrink: 0 }}>Emails</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box>
                      {emails &&
                        emails.map((email: EmailData, index: any) => (
                          <ListItem
                            key={index}
                            disableGutters
                            sx={{ alignItems: 'flex-start', py: 3 }}
                          >
                            <Avatar
                              //                             src={`${process.env.REACT_APP_HOST_API_KEY}${email}`}
                              src={`${HOST_API_KEY}${email}`}
                              sx={{ mr: 2, width: 48, height: 48 }}
                            />
                            <Stack>
                              <Typography variant="subtitle1">
                                {`${email.UserFname} ${email.UserLname}`}{' '}
                              </Typography>
                              <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                                {email.CreatedDate}
                              </Typography>
                              <Typography variant="body1" sx={{ mt: 1 }}>
                                {email.EmailSubject}
                              </Typography>
                              <Typography
                                variant="body2"
                                dangerouslySetInnerHTML={{ __html: email.EmailSummary }}
                                sx={{ mt: 1, whiteSpace: 'pre-line', color: 'grey' }} // Apply white text color here
                              />
                            </Stack>
                          </ListItem>
                        ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Divider sx={{ my: 2 }} />
              </Grid>
            </Grid>
            <Grid item lg={12}>
              <Typography
                id="Notes"
                typography="h6"
                color="yellowgreen"
                sx={{
                  marginTop: 2,
                  width: '180px',
                  borderRadius: '5px',
                  marginLeft: '-40px',
                }}
              >
                Documents
              </Typography>
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleAccordianChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={<Iconify icon="" />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography sx={{ flex: '1', width: '33%', flexShrink: 0 }}>
                    Public Documents
                  </Typography>
                  {expanded !== 'panel1' && (
                    <>
                      {/* <Typography sx={{ color: 'text.secondary' }}>Public Document</Typography> */}
                      {attachments &&
                        attachments.map(
                          (list, _key) =>
                            list.JobAttachCategory === 'public' && (
                              <FileThumbnail file={list.JobAttachExtension} />
                            )
                        )}
                    </>
                  )}
                  {expanded === 'panel1' && !edit && (
                    <Tooltip title="This button uploads the document" placement="top">
                      <Button
                        variant="contained"
                        startIcon={<Iconify icon="eva:cloud-upload-fill" />}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleOpenUploadFile('public');
                        }}
                      >
                        Upload
                      </Button>
                    </Tooltip>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <FileNewFolderDialog
                    open={openUploadFile}
                    onClose={handleCloseUploadFile}
                    job={data}
                    JobAttachCategory={fileType}
                  />

                  {attachments &&
                    attachments.map(
                      (list, key) =>
                        list.JobAttachCategory === 'public' && (
                          <StyledRoot sx={{ bgcolor: 'background.paper' }}>
                            <Stack
                              key={key}
                              spacing={2}
                              direction="row"
                              alignItems="center"
                              sx={{
                                my: 1,
                                px: 1,
                                py: 0.75,
                              }}
                            >
                              <FileThumbnail file={list.JobAttachExtension} />

                              <Stack flexGrow={1} sx={{ minWidth: 0 }}>
                                <Typography variant="subtitle2" noWrap>
                                  {list.JobAttachName}
                                </Typography>
                                <Typography variant="subtitle2" noWrap>
                                  {formatFileSize(list.JobAttachSize)}
                                </Typography>
                              </Stack>

                              <FileAction
                                publi
                                id="mailActions"
                                onRename={() =>
                                  handleRenameOpen(list.JobAttachID, list.JobAttachName)
                                }
                                onDownload={() =>
                                  handleFileDownload(
                                    list.JobAttachLoc,
                                    list.JobAttachExtension,
                                    list.JobAttachName
                                  )
                                }
                                onArchive={() => markAsConfidential(list.JobAttachID)}
                                onDelete={() => handleDeleteOpen(list.JobAttachID)}
                                onView={() =>
                                  handleFileOpen(
                                    list.JobAttachLoc,
                                    list.JobAttachExtension,
                                    list.JobAttachName
                                  )
                                }
                              />
                            </Stack>
                          </StyledRoot>
                        )
                    )}
                </AccordionDetails>
              </Accordion>
              {(user?.UserRole === 'Management' || (user?.UserRole === 'Workflow' && user?.ID === 3)) && (
                <Accordion
                  expanded={expanded === 'panel2'}
                  onChange={handleAccordianChange('panel2')}
                  sx={{ marginBottom: 3 }}
                >
                  <AccordionSummary
                    expandIcon={<Iconify icon="" />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography sx={{ flex: '1', width: '33%', flexShrink: 0 }}>
                      Confidential Documents
                    </Typography>
                    {expanded !== 'panel2' && (
                      <>
                        {/* <Typography sx={{ color: 'text.secondary' }}>Public Document</Typography> */}
                        {attachments &&
                          attachments.map(
                            (list, _key) =>
                              list.JobAttachCategory === 'confidential' && (
                                <FileThumbnail file={list.JobAttachExtension} />
                              )
                          )}
                      </>
                    )}

                    {expanded === 'panel2' && !edit && (
                      <Tooltip title="This button uploads the document" placement="top">
                        <Button
                          variant="contained"
                          startIcon={<Iconify icon="eva:cloud-upload-fill" />}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleOpenUploadFile('confidential');
                          }}
                        >
                          Upload
                        </Button>
                      </Tooltip>
                    )}
                  </AccordionSummary>
                  <AccordionDetails>
                    <FileNewFolderDialog
                      open={openUploadFile}
                      onClose={handleCloseUploadFile}
                      job={data}
                      JobAttachCategory={fileType}
                    />

                    {attachments &&
                      attachments.map(
                        (list, key) =>
                          list.JobAttachCategory === 'confidential' && (
                            <StyledRoot sx={{ bgcolor: 'background.paper' }}>
                              <Stack
                                key={key}
                                spacing={2}
                                direction="row"
                                alignItems="center"
                                sx={{
                                  my: 1,
                                  px: 1,
                                  py: 0.75,
                                }}
                              >
                                <FileThumbnail file={list.JobAttachExtension} />

                                <Stack flexGrow={1} sx={{ minWidth: 0 }}>
                                  <Typography variant="subtitle2" noWrap>
                                    {list.JobAttachName}
                                  </Typography>
                                  <Typography variant="subtitle2" noWrap>
                                    {formatFileSize(list.JobAttachSize)}
                                  </Typography>
                                </Stack>

                                <FileAction
                                  id="mailActions"
                                  publi={false}
                                  onRename={() =>
                                    handleRenameOpen(list.JobAttachID, list.JobAttachName)
                                  }
                                  onDownload={() =>
                                    handleFileDownload(
                                      list.JobAttachLoc,
                                      list.JobAttachExtension,
                                      list.JobAttachName
                                    )
                                  }
                                  onArchive={() => markAsPublic(list.JobAttachID)}
                                  onDelete={() => handleDeleteOpen(list.JobAttachID)}
                                  onView={() =>
                                    handleFileOpen(
                                      list.JobAttachLoc,
                                      list.JobAttachExtension,
                                      list.JobAttachName
                                    )
                                  }
                                />
                              </Stack>
                            </StyledRoot>
                          )
                      )}
                  </AccordionDetails>
                </Accordion>
              )}

              {/* ------- */}
              <Typography
                id="Notes"
                typography="h6"
                color="yellowgreen"
                sx={{
                  marginTop: 2,
                  borderRadius: '5px',
                  marginLeft: '-40px',
                }}
              >
                See All Job Stage Checklists
              </Typography>
              <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleAccordianChange('panel3')}
                sx={{ marginBottom: 3 }}
              >
                <AccordionSummary
                  expandIcon={<Iconify icon="" />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography sx={{ flex: '1', width: '33%', flexShrink: 0 }}>Stages</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {jobStageData
                    ? Object.entries(groupByStage(jobStageData)).map(
                        ([stageN, checklistGroups], index) => (
                          <div key={stageN}>
                            {Object.entries(checklistGroups).map(
                              ([checklistName, records], idx) => (
                                <div key={checklistName}>
                                  <Typography variant="h6" sx={{ marginBottom: 2, marginTop: 2 }}>
                                    {stageN} - {checklistName}
                                  </Typography>
                                  <TableContainer style={{ height: '220px', overflow: 'auto' }}>
                                    <Table component={Paper} stickyHeader>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell sx={{ backgroundColor: '#232222' }}>
                                            Question
                                          </TableCell>
                                          <TableCell sx={{ backgroundColor: '#232222' }}>
                                            Yes
                                          </TableCell>
                                          <TableCell sx={{ backgroundColor: '#232222' }}>
                                            No
                                          </TableCell>
                                          <TableCell sx={{ backgroundColor: '#232222' }}>
                                            N/A
                                          </TableCell>
                                          <TableCell sx={{ backgroundColor: '#232222' }}>
                                            Notes
                                          </TableCell>
                                          <TableCell sx={{ backgroundColor: '#232222' }}>
                                            Modified By
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {records?.map((item, i) => (
                                          <StyledTableRow key={i}>
                                            <StyledTableCell>{item.Question}</StyledTableCell>
                                            <StyledTableCell>
                                              {item.QueTopic === 'q' && item.Answer === 'Y'
                                                ? '✓'
                                                : ''}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              {item.QueTopic === 'q' && item.Answer === 'N'
                                                ? '✓'
                                                : ''}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              {item.QueTopic === 'q' && item.Answer === 'NA'
                                                ? '✓'
                                                : ''}
                                            </StyledTableCell>
                                            <StyledTableCell>{item.Notes}</StyledTableCell>
                                            <StyledTableCell>
                                              {item.UserFname} {item.UserLname}
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                  {idx < Object.keys(checklistGroups).length - 1 && (
                                    <Divider sx={{ my: 3 }} />
                                  )}
                                </div>
                              )
                            )}
                            <Divider sx={{ my: 3 }} />
                          </div>
                        )
                      )
                    : 'No Data Available'}
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item lg={12}>
              <Typography id="Notes" sx={{ fontSize: '20px', marginTop: 1 }}>
                Comments
              </Typography>
              {!edit && (
                <Stack spacing={3} alignItems="flex-end">
                  <TextField
                    name="comment"
                    fullWidth
                    placeholder="Write some of your comments..."
                    multiline
                    rows={3}
                    inputRef={ref}
                  />
                  <Tooltip title="Click to post your comment" placement="top">
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={issubmitting}
                      onClick={postComment}
                    >
                      Post comment
                    </LoadingButton>
                  </Tooltip>
                </Stack>
              )}
              <Box>
                {comments &&
                  comments.map((comment, index) => (
                    <ListItem key={index} disableGutters sx={{ alignItems: 'flex-start', py: 3 }}>
                      <Avatar
                        //                        src={`${process.env.REACT_APP_HOST_API_KEY}${comment.UserProfilePic}`}
                        src={`${HOST_API_KEY}${comment.UserProfilePic}`}
                        sx={{ mr: 2, width: 48, height: 48 }}
                      />
                      <Stack>
                        <Typography variant="subtitle1">
                          {`${comment.UserFname} ${comment.UserLname}`}{' '}
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                          {comment.CreatedAt}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1, whiteSpace: 'pre-line' }}>
                          {comment.CommentMsg}
                        </Typography>
                      </Stack>
                    </ListItem>
                  ))}
              </Box>
            </Grid>
          </>
        )}
      </Container>
    </Container>
  );
}
function updateFunction(jobSlaTime: any) {
  throw new Error('Function not implemented.');
}
